import {
  Button,
  Collapse,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { open_snack_ac } from '../actions/snack.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  formControl: {
    marginRight: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 8,
    flexDirection: 'row'
  }
})
export const isValidPhoneNumber = n => {
  n = n
    .toString()
    .trim()
    .replace(/[^0-9]/g, '')
  if (n.length === 11) {
    return n.substr(0, 1) === '1'
  }
  return n.length === 10
}

const AR_PHONE_NUMS = {
  mcl: '877-768-1295',
  mco: '877-769-1541',
  mcr: '800-259-8123'
}

const PATIENT_NOTIF_OPTIONS = [
  {
    category: 'Requesting and Awaiting Docs from MD',
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['doctor'],
    subject: `Order Update from SuperCare Health`,
    sms: (name, phone) =>
      `SuperCare Health has requested additional info from your doctor to fulfill your order.  Stay tuned.`,
    email: (name, phone) =>
      `Hello ${name}, Your order with SuperCare Health is in process. We have requested additional medical records from your physician. We will follow up with you directly to ask for assistance only if we do not get the needed information. `
  },
  {
    category: 'Repeat Request from MD, Still Waiting. Pt to call us back',
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['doctor'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health did not receive info requested from your doctor. Please call us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name} - We have not received the requested documents from your MD.  Please contact us at ${phone} to discuss your options and our next steps.`
  },

  {
    category: `Necessary Docs in Place. Processing Order`,
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['doctor', 'payer', 'other'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `This is SuperCare Health. Your order is being processed. Delevery scheduling is happening next.`,
    email: (name, phone) =>
      `Hello ${name}, We have all necessary information and are processing your order. For inquiries, you can contact us at ${phone}`
  },

  {
    category: `Requesting and Awaiting Docs from Payer`,
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['payer'],
    subject: `Order Update from SuperCare Health`,
    sms: (name, phone) =>
      `SuperCare Health has requested authorization from your insurance to fulfill your order. Stay tuned.`,
    email: (name, phone) =>
      `Hello ${name}, We have requested an authorization from your insurance. We will contact you directly for asisstance only if we do not receive the needed authorization.`
  },

  {
    category: `Repeat Request to Payer, Still Waiting. Pt to call us back`,
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['payer'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health has not received the requested authorization. Please call us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name}, We have not received the requested authorization, please contact us at ${phone}.`
  },

  {
    category: `Requesting and Awaiting Docs`,
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['other'],
    subject: `Order Assistance Requested from SuperCare Health`,
    sms: (name, phone) =>
      `SuperCare Health requested additional medical records to fulfill your order. Stay tuned.`,
    email: (name, phone) =>
      `Hello ${name}, Your order with SuperCare Health is in process.  We have requested additional medical records to fulfill your order.  We will follow up with you directly for assistance only if we do not get the needed information.`
  },

  {
    category: `Repeat Request, Still Waiting. Pt to call us back`,
    transmissionMethod: [
      'fax',
      'email',
      'voice',
      'runner',
      'snailmail',
      'portal'
    ],
    recipientType: ['other'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health did not receive the requested medical records. Please call us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name}, We have not received the medical documents we requested. Please contact us at ${phone} to discuss your options and our next steps.`
  },

  //to patients
  {
    category: `Need more info from the patient`,
    recipientType: ['patient'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health needs additonal info to fulfill your order. Please call us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name}, We are working on your order and need to speak with you. Please contact us at ${phone}`
  },

  {
    category: `We are processing your order. Stand by`,
    recipientType: ['patient'],
    subject: `Order Update from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `We have everything we need to process your order.  Please stand by for delivery information.`,
    email: (name, phone) =>
      `Hello ${name}, We have everything we need to process your order.  Please stand by for delivery information.`
  },

  {
    category: `SCH can't reach the patient. Call us back`,
    recipientType: ['patient'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health has been unable to reach you. Please contact us at ${phone} to discuss your order.`,
    email: (name, phone) =>
      `Hello ${name}, We are working on your order and and unable to reach you. Please contact us at ${phone}.`
  },

  {
    category: `SCH can't process your order. Call us back`,
    recipientType: ['patient'],
    subject: `Order Assistance Requested from SuperCare Health`,
    phone: `800-206-4880`,
    sms: (name, phone) =>
      `SuperCare Health cannot process your order. Please contact us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name}, We are having problems processing your order.  Please contact us at ${phone} to discuss.`
  },

  {
    category: `To continue services, SCH needs docs from MD. Pt to call us back`,
    recipientType: ['patient'],
    subject: `Order Update from SuperCare Health`,
    sms: (name, phone) =>
      `To continue your services, SuperCare Health needs additional documentation from your doctor. Please contact us at ${phone} to discuss.`,
    email: (name, phone) =>
      `Hello ${name}, It is time to visit your MD.  Please ask your MD to fax documents to our office after your visit to fax# ${phone}.`
  },

  {
    category: `Open AR balance. Pt to call us back`,
    recipientType: ['patient'],
    subject: `Requested Payment from SuperCare Health`,
    sms: (name, phone) =>
      `SuperCare Health shows that you have an outstanding balance. Please call us at ${phone} to resolve.`,
    email: (name, phone) =>
      `Hello ${name}, You have an oustanding balance that needs to be resolved before we can fulfill your order.  Please contact us at ${phone} so we can process your order.`
  }
]

//lowercase all letters in name except first letter of each part
const niceName = nm => {
  return nm
    .split(/ /g)
    .map(n => {
      if (n.length === 1) return n
      return n.substr(0, 1) + n.substr(1).toLowerCase()
    })
    .join(' ')
}

class PatientNotifForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      txt: '',
      expanded: false,
      emailSubject: '',
      emailTxt: '',
      confirmed: false,
      smsTxt: '',
      previewTxt: '',
      callBackNumber: props.callBackNumber || 'xxx-xxx-xxxx',
      callBackNumberValid: props.callBackNumber
        ? isValidPhoneNumber(props.callBackNumber)
        : false,
      selectedMsgCategory: 'na',
      isSendingNotification: false
    }
    this.onSend = this.onSend.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
    this.onConfirmNotif = this.onConfirmNotif.bind(this)
    this.onClear = this.onClear.bind(this)
    this.determineCannedMessages = this.determineCannedMessages.bind(this)
    this.onCannedMsgSelected = this.onCannedMsgSelected.bind(this)
    this.effectiveNotifContact = this.effectiveNotifContact.bind(this)
    this.onCallBackNumberChange = this.onCallBackNumberChange.bind(this)
  }

  effectiveNotifContact() {
    const { idnAccountInfo, hdmsAccountPos } = this.props
    if (!idnAccountInfo.pkg) return null
    if (!idnAccountInfo.pkg.notif_contacts) return null
    if (!idnAccountInfo.pkg.notif_contacts.length === 0) return null

    let ncs = idnAccountInfo.pkg.notif_contacts.filter(nc => {
      return (
        nc.Account === idnAccountInfo.pkg.accounts[hdmsAccountPos].account &&
        nc.Member === idnAccountInfo.pkg.accounts[hdmsAccountPos].member
        //&& nc.is_verified REMOVED per changes 12-14-2020, no need to verify
      )
    })

    if (ncs.length === 0) return null

    ncs[0].method_prefs = []

    if (true === ncs[0].Preferences.Notifications.PatientOrderStatus.Email) {
      ncs[0].method_prefs.push('Email')
    }
    if (true === ncs[0].Preferences.Notifications.PatientOrderStatus.SMS) {
      ncs[0].method_prefs.push('SMS')
    }

    //lowercase the onboarding name
    ncs[0].OnboardingName = niceName(ncs[0].OnboardingName)

    return ncs[0]
  }

  determineCannedMessages() {
    const { selectedRecipientType } = this.props
    if (selectedRecipientType) {
      return PATIENT_NOTIF_OPTIONS.filter(p => {
        return (
          //p.transmissionMethod.includes(selectedTransmissionType) &&
          p.recipientType.includes(selectedRecipientType)
        )
      })
    }
    return []
  }

  onConfirmNotif() {
    const ncs = this.effectiveNotifContact()
    this.setState({ confirmed: true })
    if (typeof this.props.onConfirmNotif === 'function') {
      this.props.onConfirmNotif({
        emailTxt: this.state.emailTxt,
        smsTxt: this.state.smsTxt,
        contactInfo: {
          Email: ncs.Email,
          SMS: ncs.SMS,
          Account: ncs.Account,
          Member: ncs.Member
        }
      })
    }
  }

  onSend() {
    this.setState({ isSendingNotification: true })
    const ncs = this.effectiveNotifContact()
    let postBody = {
      Account: ncs.Account,
      Member: ncs.Member,
      Subject: this.state.emailSubject
    }

    if (this.state.emailTxt !== '' && this.state.smsTxt !== '') {
      postBody.Body = this.state.emailTxt
      postBody.BodySMS = this.state.smsTxt
    } else {
      postBody.Body = this.state.emailTxt || this.state.smsTxt
    }
    return window.sch
      .post(`/api/notifs/patients/sendnote`, postBody)
      .then(result => {
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Notification sent'
        })
        this.props.onRefreshNotifHistory()
        setTimeout(() => {
          this.setState({ isSendingNotification: false })
        }, 3000)
      })
      .catch(err => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed sending notification'
        })
        setTimeout(() => {
          this.setState({ isSendingNotification: false })
        }, 3000)
      })
  }

  onClear() {
    this.setState({ confirmed: false })
    if (typeof this.props.onConfirmNotif === 'function') {
      this.props.onConfirmNotif(null)
    }
  }

  onArPhoneClicked = code => () => {
    this.onCallBackNumberChange({ target: { value: AR_PHONE_NUMS[code] } })
  }

  onCallBackNumberChange(e) {
    this.setState({
      callBackNumber: e.target.value,
      callBackNumberValid: isValidPhoneNumber(e.target.value)
    })
    this.onCannedMsgSelected(
      {
        target: { value: this.state.selectedMsgCategory }
      },
      null,
      e.target.value
    )
  }

  onCannedMsgSelected(e, f = null, callbackNumber = null) {
    let emailTxt = '',
      smsTxt = ''
    if (e.target.value === 'na') {
      this.setState({
        selectedMsgCategory: e.target.value,
        emailSubject: '',
        emailTxt,
        smsTxt,
        confirmed: false
      })
      this.onClear()
      return
    }

    let cms = this.determineCannedMessages()

    if (cms.length === 0) return

    let cm = cms.filter(cm => cm.category === e.target.value)
    if (cm.length === 0) return
    cm = cm[0]

    let enc = this.effectiveNotifContact()

    let _newState = {
      selectedMsgCategory: e.target.value,
      emailSubject: cm.subject.toString(),
      emailTxt,
      smsTxt,
      confirmed: true
    }

    if (callbackNumber === null && cm.phone) {
      //update it to the default callback num
      _newState.callbackNumber = cm.phone.toString()
      callbackNumber = cm.phone.toString()
    }

    let applyMethods = []
    if (enc.Preferences.Notifications.PatientOrderStatus.Email) {
      emailTxt = cm.email(
        enc.OnboardingName,
        callbackNumber || this.state.callBackNumber
      )
      applyMethods.push('Email')
    }

    if (enc.Preferences.Notifications.PatientOrderStatus.SMS) {
      smsTxt = cm.sms(
        enc.OnboardingName,
        callbackNumber || this.state.callBackNumber
      )
      applyMethods.push('SMS')
    }

    _newState.emailTxt = emailTxt
    _newState.smsTxt = smsTxt

    this.setState(_newState)

    const ncs = this.effectiveNotifContact()
    if (typeof this.props.onConfirmNotif === 'function') {
      this.props.onConfirmNotif({
        emailTxt,
        smsTxt,
        emailSubject: cm.subject.toString(),
        contactInfo: {
          Email: ncs.Email,
          SMS: ncs.SMS,
          Account: ncs.Account,
          Member: ncs.Member
        },
        applyMethods
      })
    }
  }

  onTextChange(e) {
    this.setState({ txt: e.target.value })
  }
  render() {
    const { classes, idnAccountInfo, me } = this.props
    const formGroupRow = { root: classes.formGroupRoot }

    if (idnAccountInfo.isFetching) {
      return (
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            ..checking Patient Onboard status
          </FormLabel>
        </FormControl>
      )
    }

    const enc = this.effectiveNotifContact()
    if (null === enc) {
      return (
        <div
          style={{ backgroundColor: '#B3E5FC', padding: '8px 8px 8px 16px' }}>
          <FormControl
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel component="label">
              {this.props.stepNumber}) Patient Notification - Patient is NOT
              Onboarded for Notifications. To Onboard patient for notifications,
              use the Accounts > Notificiations Tab.
            </FormLabel>
          </FormControl>
        </div>
      )
    }
    if (enc.method_prefs.length === 0) {
      return (
        <div
          style={{ backgroundColor: '#B3E5FC', padding: '8px 8px 8px 16px' }}>
          <FormControl
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel component="label">
              {this.props.stepNumber}) Patient Notification - Patient is
              Onboarded but has OPTED OUT OF notifications.
            </FormLabel>
          </FormControl>
        </div>
      )
    }

    /*
    if (
      idnAccountInfo.pkg.notif_contacts.filter(
        nc => nc.Timestamps.Verified !== 'UNVERIFIED'
      ).length === 0
    ) {
      return (
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            Patient is NOT Onboarded for Notifications
          </FormLabel>
        </FormControl>
      )
    }
    */

    const cannedMsgs = this.determineCannedMessages()

    /* determine if callback number is required and invalid */
    const callbackNumberRelevant =
      this.state.emailTxt.search(this.state.callBackNumber) !== -1 ||
      this.state.smsTxt.search(this.state.callBackNumber) !== -1

    let conatinerDiv = (
      <div style={{ backgroundColor: '#B3E5FC', padding: '8px 8px 64px 16px' }}>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            {this.props.stepNumber}) Patient Notification
          </FormLabel>
          <FormHelperText style={{ fontSize: 12 }}>
            Patient Contact {enc.OnboardingName} ({enc.OnboardingRelationship})
            is Onboarded for Notifications - with Preference:{' '}
            {enc.method_prefs.join(' and ')}
          </FormHelperText>
        </FormControl>

        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel style={{ fontSize: 10 }} component="label">
            Select a Message
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            <Select
              className={classes.selectElement}
              input={<Input name="team" />}
              onChange={this.onCannedMsgSelected}
              value={this.state.selectedMsgCategory}>
              <MenuItem className={classes.menuItem} value={'na'}>
                Do Not Send Message to Patient
              </MenuItem>
              {cannedMsgs.map(cm => (
                <MenuItem
                  className={classes.menuItem}
                  key={cm.category}
                  value={cm.category}>
                  {cm.category}
                </MenuItem>
              ))}
            </Select>
          </FormGroup>
        </FormControl>

        {this.props.showARPhoneNumberButtons && (
          <FormControl
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel
              style={{ fontSize: 10, fontWeight: 'bold' }}
              component="label">
              Type in Callback Phone Number or choose from:{' '}
              <Button
                variant="outlined"
                onClick={this.onArPhoneClicked('mcr')}
                size="small">
                MCR
              </Button>
              <Button
                variant="outlined"
                onClick={this.onArPhoneClicked('mcl')}
                size="small">
                Medi-Cal
              </Button>
              <Button
                variant="outlined"
                onClick={this.onArPhoneClicked('mco')}
                size="small">
                MCO
              </Button>
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                onChange={this.onCallBackNumberChange}
                value={this.state.callBackNumber}
                style={{ margin: '8px 8px 8px 0' }}
                placeholder=""
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.confirmed && this.state.emailTxt.length > 0 && (
          <FormControl component="fieldset" className={classes.formControl}>
            <label style={{ fontSize: 11 }}>
              Preview of Email to Patient: <b>{enc.Email}</b>
            </label>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                padding: 24,
                fontSize: 12,
                backgroundColor: '#ADD8E6'
              }}>
              Subject: {this.state.emailSubject}
              {'\n\n'}
              {this.state.emailTxt}
            </pre>
          </FormControl>
        )}
        {this.state.confirmed && this.state.smsTxt.length > 0 && (
          <FormControl component="fieldset" className={classes.formControl}>
            <label style={{ fontSize: 11 }}>
              Preview of SMS Message to Patient: <b>{enc.SMS}</b>
            </label>
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                padding: 24,
                fontSize: 12,
                backgroundColor: '#ADD8E6'
              }}>
              {this.state.smsTxt}
            </pre>
          </FormControl>
        )}
        {me.user.roles.indexOf('allow-notify-patient') === -1 && (
          <FormControl
            required
            component="fieldset"
            className={classes.formControl}>
            <span style={{ fontSize: 10 }}>
              Feature disabled - user must have role 'allow-notify-patient'
            </span>
          </FormControl>
        )}

        {!this.state.callBackNumberValid && callbackNumberRelevant && (
          <FormControl
            required
            component="fieldset"
            className={classes.formControl}>
            <span style={{ fontSize: 10, fontWeight: 'bold', color: 'red' }}>
              Callback Number is REQUIRED and must be in VALID FROMAT
              (XXX-XXX-XXXX) for this message.
            </span>
          </FormControl>
        )}

        {me.user.roles.indexOf('allow-notify-patient') !== -1 &&
          this.props.enableSendButton && (
            <FormControl
              required
              component="fieldset"
              className={classes.formControl}>
              <Button
                onClick={this.onSend}
                disabled={
                  !this.state.confirmed ||
                  this.state.isSendingNotification ||
                  (!this.state.callBackNumberValid && callbackNumberRelevant)
                }
                variant="contained"
                size="small"
                color="secondary"
                className={classes.button}>
                Send this Message
              </Button>
            </FormControl>
          )}
      </div>
    )
    if (this.props.collapseable) {
      return (
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          {conatinerDiv}
        </Collapse>
      )
    }
    return conatinerDiv
  }
}

PatientNotifForm.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me
  //idnAccountInfo: stateFromStore.idnAccountInfo
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientNotifForm))
)
