import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Attachment as AttachmentIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  attachFilesToStore_ac,
  createOpsFormRecord_ac,
  pre_createOpsFormRecord_ac,
  resetOpsFormRecordActivity_ac,
  undo_createOpsFormRecord_ac,
} from '../../actions/ops_forms.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import InitialVentMetaForm from './InitialVentMetaForm.js';
import TemplateForm from './TemplateForm';
import { isValidPhoneNumber } from '../PatientNotifForm.js';

/*
const isFaxNumber = n => {
  n = n
    .toString()
    .trim()
    .replace(/[^0-9]/g, '')
    .replace(/[()]/g, '')
  if (n.length === 11) {
    return n.substr(0, 1) === '1'
  }
  return n.length === 10
}
const isEmailAddress = n => {
  return (
    0 ===
    n
      .toString()
      .trim()
      .search(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
  )
}
*/

export const isValidEmail = (email) => {
  if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
    return false;
  }
  return true;
};


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },
  cardHdrRoot: {
    paddingBottom: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
    color: '#000',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardContentRoot: {
    paddingBottom: 40,
    marginBottom: 40,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  listItemText: {
    color: '#3F51B5',
    fontWeight: 600,
    fontSize: '.92em',
  },

  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    marginTop: 6,
    color: '#008AE0',
  },
  slaPasses: {
    color: '#E51400',
    marginBottom: 6,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteTxt: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    color: '#244e91',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  formLabel: {
    position: 'initial',
    marginBottom: '-16px',
  },
  formGroupChild: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      flexBasis: 'calc(12% - 32px)',
    },
    [theme.breakpoints.down('lg')]: {
      flexBasis: 'calc(16% - 32px)',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: 'calc(20% - 32px)',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'calc(33% - 32px)',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: 'calc(50% - 32px)',
    },
  },
});

const TPL_BG_COLORS = [
  '#DCEDC8',
  '#C8E6C9',
  '#C5E1A5',
  '#A5D6A7',
  '#81C784',
  '#AED581',
  '#9CCC65',
  '#8BC34A',
  '#7CB342',
  '#689F38',
];

const FORMS_REQUIRING_TASK = [
  'vent_setting_change',
  'vent_exchange',
  'vent_exchange_two',
];

class OpsForm extends React.Component {
  constructor(props) {
    super(props);

    let work_orders = [];
    if (this.props.match.params.extras) {
      let ex_parts = this.props.match.params.extras.split(/,/g);
      work_orders = ex_parts
        .filter((p) => {
          return p.search(/^[WM][0-9]{3,}/i) === 0;
        })
        .map((w) => w.toUpperCase());
      console.log(work_orders);
    }

    this.state = {
      error: null,
      work_orders,
      spawn_tasks: [],
      localKeysSaved: [],
      selectedHDMSAccountPosition: '0',
      otherRecipientType: '',
      patientNotification: null,
      memoText: '',
      contactInfo: {},
      isPreSaving: {
        vent: false,
        wheelchair_assessment: false,
      },
      finalPresave: {
        vent: false,
        wheelchair_assessment: false,
      },
      visitTypes: [
        {
          code: 'initial',
          lbl: 'Initial',
          checked: false,
        },
        {
          code: 'follow_up',
          lbl: 'Follow Up',
          checked: false,
        },
      ],
      typesOfForms: [
        {
          code: 'vent',
          lbl: 'Ventilator Equipment',
          checked: false,
        },
        {
          disabled: false,
          code: 'wheelchair_assessment',
          lbl: 'Wheelchair Assessment',
          checked: false,
        },
      ],
      therapyTypes: [
        {
          code: 'invasive',
          lbl: 'Invasive',
          checked: false,
        },
        {
          code: 'non_invasive',
          lbl: 'Non Invasive',
          checked: false,
        },
      ],

      formsListing: [
        {
          code: 'trilogy_vent_settings',
          lbl: 'Trilogy Vent Settings',
          checked: false,
          tplId: 'trilogy_vent_settings',
          isEquipModel: true,
        },
        {
          code: 'evo_vent_settings',
          lbl: 'EVO Vent Settings',
          checked: false,
          isEquipModel: true,
          tplId: 'evo_vent_settings',
        },
        {
          code: 'vivo45_vent_settings',
          lbl: 'VIVO-45 Vent Settings',
          checked: false,
          isEquipModel: true,
          tplId: 'vivo45_vent_settings',
        },
        {
          code: 'astral_vent_settings',
          lbl: 'Astral Vent Settings',
          checked: false,
          isEquipModel: true,
          tplId: 'astral_vent_settings',
        },
        {
          code: 'ltv_vent_settings',
          lbl: 'LTV Vent Settings',
          checked: false,
          isEquipModel: true,
          tplId: 'ltv_vent_settings',
        },
        {
          code: 'ht_vent_settings',
          lbl: 'HT Vent Settings',
          checked: false,
          isEquipModel: true,
          tplId: 'ht_vent_settings',
        },
        {
          code: 'generic_vent_settings',
          lbl: 'Generic Vent Settings',
          isEquipModel: true,
          checked: false,
          tplId: 'generic_vent_settings',
        },

        //dependent on visit and therapy type
        {
          code: 'vent_setting_change',
          lbl: 'Vent Setting Change',
          checked: false,
          tplId: 'vent_setting_change',
        },
        {
          code: 'vent_exchange',
          lbl: 'Primary Vent Exchange',
          checked: false,
          tplId: 'vent_exchange',
        },
        {
          code: 'vent_exchange_two',
          lbl: 'Secondary Vent Exchange',
          checked: false,
          tplId: 'vent_exchange_two',
        },
        {
          code: 'trach_info',
          lbl: 'Trach Info',
          checked: false,
          tplId: 'trach_info',
        },
        {
          code: 'niv_mask',
          lbl: 'NIV Mask',
          checked: false,
          tplId: 'niv_mask',
        },
        {
          code: 'additional_therapies',
          lbl: 'Additional Therapies',
          checked: false,
          tplId: 'additional_therapies',
        },
        {
          code: 'medications',
          lbl: 'Medications',
          checked: false,
          tplId: 'medications',
        },
        {
          code: 'vent_dl',
          lbl: 'Vent Download Data',
          checked: false,
          tplId: 'vent_dl',
        },
        {
          checked: false,
          tplId: 'wheelchair_home_assessment',
          code: 'wheelchair_home_assessment',
          lbl: 'Manual Wheelchair Home Assessment',
        },
        /*{
        code: 'rt_comments',
        lbl: 'Rt Comments',
        checked: false,
        tplId: 'rt_comments'
      }*/
      ],
      selectedTypeOfForm: null,
      selectedTypeOfVisit: null,
      selectedTypeOfTherapy: null,
    };

    this.handleCreateDocRequest = this.handleCreateDocRequest.bind(this);
    this.onMemoTextChange = this.onMemoTextChange.bind(this);
    this.onContactInfoChange = this.onContactInfoChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.resetToInitialState = this.resetToInitialState.bind(this);
    this.uponLocalSave = this.uponLocalSave.bind(this);
    this.onTypeOfVisitSelected = this.onTypeOfVisitSelected.bind(this);
    this.onTypeOfTherapySelected = this.onTypeOfTherapySelected.bind(this);
    this.autoCheckForms = this.autoCheckForms.bind(this);
    this.hdmsAccountPositionSelected =
      this.hdmsAccountPositionSelected.bind(this);
    this.onTypeOfFormChange = this.onTypeOfFormChange.bind(this);
    this.validateContactData = this.validateContactData.bind(this);
  }

  hdmsAccountPositionSelected(evt, value) {
    this.setState({ selectedHDMSAccountPosition: value.toString() });
  }

  autoCheckForms(pos, val) {
    if (
      this.state.selectedTypeOfVisit === null &&
      null === this.state.selectedTypeOfTherapy
    )
      return [];

    let k = [
      pos === 0 ? val : this.state.selectedTypeOfVisit,
      pos === 1 ? val : this.state.selectedTypeOfTherapy,
    ].join('-');

    switch (k) {
      case 'initial-invasive':
        return ['trach_info', 'medications', 'additional_therapies'];
      case 'initial-non_invasive':
        return ['niv_mask', 'medications', 'additional_therapies'];
      case 'follow_up-invasive':
        return ['trach_info', 'medications', 'additional_therapies', 'vent_dl'];
      case 'follow_up-non_invasive':
        return ['niv_mask', 'medications', 'additional_therapies', 'vent_dl'];
      default:
        return [];
    }
  }

  onTypeOfFormChange(evt, value) {
    this.setState({
      selectedTypeOfForm: value,
    });

    if (value !== 'wheelchair_assessment') return;

    let wheelchairFieldIndex = this.state.formsListing.findIndex(
      (form) => form.code === 'wheelchair_home_assessment'
    );

    if (wheelchairFieldIndex === -1) return;

    if (!this.state.formsListing[wheelchairFieldIndex].checked)
      this.formSelected('wheelchair_home_assessment')();
  }

  evalSpawnTaskRequirements({ event_kind, val }) {
    if (event_kind === 'type_of_visit') {
      if (val === 'initial') {
        return ['vent_settings_review'];
      }
      //check state
      return this.state.formsListing.filter((f) => {
        return FORMS_REQUIRING_TASK.includes(f.code) && f.checked;
      }).length > 0
        ? ['vent_settings_review']
        : [];
    }

    if (event_kind === 'form_selection') {
      if (this.state.selectedTypeOfVisit === 'initial') {
        return ['vent_settings_review'];
      }

      //val is a clone of this.state.formsListing (including the change)
      return val.filter((f) => {
        return FORMS_REQUIRING_TASK.includes(f.code) && f.checked;
      }).length > 0
        ? ['vent_settings_review']
        : [];
    }
  }

  onTypeOfVisitSelected(evt, value) {
    let to_check = this.autoCheckForms(0, value);
    console.log(to_check);
    this.setState({
      selectedTypeOfVisit: value.toString(),
      spawn_tasks: this.evalSpawnTaskRequirements({
        event_kind: 'type_of_visit',
        val: value,
      }),

      formsListing: this.state.formsListing.map((f) => {
        if (true !== f.isEquipModel) {
          f.checked = to_check.includes(f.code);
        }
        return f;
      }),
    });
  }

  onTypeOfTherapySelected(evt, value) {
    let to_check = this.autoCheckForms(1, value);
    console.log(to_check);
    this.setState({
      selectedTypeOfTherapy: value.toString(),
      formsListing: this.state.formsListing.map((f) => {
        if (true !== f.isEquipModel) {
          f.checked = to_check.includes(f.code);
        }
        return f;
      }),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.opsFormRecordActivity.state !== 'success' &&
      this.props.opsFormRecordActivity.state === 'success'
    ) {
      this.state.localKeysSaved.forEach((k) => {
        window.localStorage.removeItem(k);
        console.log('removing ' + k + ' from local storage');
      });
    }
  }

  resetToInitialState() {
    this.setState((state) => ({
      error: null,
      spawn_tasks: [],
      contactInfo: {},
      selectedHDMSAccountPosition: '0',
      otherRecipientType: '',
      localKeysSaved: [],
      patientNotification: null,
      memoText: '',
      isPreSaving: {
        vent:
          state.selectedTypeOfForm === 'vent' ? false : state.isPreSaving.vent,
        wheelchair_assessment:
          state.selectedTypeOfForm === 'wheelchair_assessment'
            ? false
            : state.isPreSaving.wheelchair_assessment,
      },
      finalPresave: {
        vent:
          state.selectedTypeOfForm === 'vent' ? false : state.finalPresave.vent,
        wheelchair_assessment:
          state.selectedTypeOfForm === 'wheelchair_assessment'
            ? false
            : state.finalPresave.wheelchair_assessment,
      },

      formsListing: this.state.formsListing.map((d) => {
        d.checked = false;
        return d;
      }),
    }));
  }

  resetForm() {
    this.props.resetOpsFormRecordActivity_ac();
    this.resetToInitialState();
  }

  onMemoTextChange(e) {
    this.setState({ memoText: e.target.value });
  }

  onContactInfoChange(info) {
    this.setState({ contactInfo: info });
  }

  formSelected = (value) => async () => {
    let dto = _.clone(this.state.formsListing);
    dto = dto.map((d) => {
      d.checked = d.code === value ? !d.checked : d.checked;
      return d;
    });

    if (typeof this.props.onOpsFormTplChange === 'function') {
      await this.props.onOpsFormTplChange(dto);
    }

    this.setState({
      formsListing: dto,
      spawn_tasks: this.evalSpawnTaskRequirements({
        event_kind: 'form_selection',
        val: dto,
      }),
    });

    return;
  };
  uponLocalSave(localKey) {
    this.setState(
      (state) => ({
        isPreSaving: {
          vent:
            state.selectedTypeOfForm === 'vent'
              ? false
              : state.isPreSaving.vent,
          wheelchair_assessment:
            state.selectedTypeOfForm === 'wheelchair_assessment'
              ? false
              : state.isPreSaving.wheelchair_assessment,
        },
        finalPresave: {
          vent:
            state.selectedTypeOfForm === 'vent'
              ? true
              : state.finalPresave.vent,
          wheelchair_assessment:
            state.selectedTypeOfForm === 'wheelchair_assessment'
              ? true
              : state.finalPresave.wheelchair_assessment,
        },
        localKeysSaved: [...this.state.localKeysSaved, localKey],
      }),
      () =>
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Form data saved locally!',
        })
    );
  }

  validateContact(contact, alias = 'Contact') {
    if (!contact) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `${alias} is required`,
      });

      return false;
    }

    const { phone, name, address, email } = contact;

    if (!phone && !email) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `Please provide any of ${alias} phone number or email`,
      });

      return false;
    }

    if (phone && !isValidPhoneNumber(phone)) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `Please provide a valid ${alias} phone number`,
      });

      return false;
    }

    if (email && !isValidEmail(email)) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `Please provide a valid ${alias} email`,
      });

      return false;
    }

    if (!name) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `${alias} Name is required`,
      });

      return false;
    }

    if (!address) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `${alias} Address is required`,
      });
      
      return false;
    }

    return true;
  }

  validateContactData() {
    if (
      this.state.selectedTypeOfForm === 'vent' &&
      this.state.selectedTypeOfVisit === 'initial'
    ) {
      const {
        responsible_for_care,
        emergency_contact,
        secondary_emergency_contact,
      } = this.state.contactInfo;
      if (!responsible_for_care) {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Please provide who is responsible for care',
        });
        return false;
      }

      if (!this.validateContact(emergency_contact, 'Emergency contact')) {
        return false;
      }

      if (
        !this.validateContact(
          secondary_emergency_contact,
          'Secondary Emergency contact'
        )
      ) {
        return false;
      }
    }

    return true;
  }

  handleCreateDocRequest() {
    // Validate first
    const ok = this.validateContactData();
    if (!ok) {
      return;
    }

    if (this.state.finalPresave[this.state.selectedTypeOfForm] === false) {
      //do another spanw_tasks check

      this.setState((state) => ({
        isPreSaving: {
          ...state.isPreSaving,
          [state.selectedTypeOfForm]: true,
          spawn_tasks:
            this.state.selectedTypeOfVisit === 'initial' ||
            this.state.formsListing.filter((f) => {
              return FORMS_REQUIRING_TASK.includes(f.code) && f.checked;
            }).length > 0
              ? ['vent_settings_review']
              : [],
        },
      }));
      return;
    }

    let forms = this.state.formsListing
      .filter(
        (form) =>
          (this.state.selectedTypeOfForm === 'wheelchair_assessment'
            ? form.code === 'wheelchair_home_assessment'
            : form.code !== 'wheelchair_home_assessment') && form.checked
      )
      .map((form) => {
        const { pages: _pages } = this.props.tplsLoaded[form.tplId].tpl;

        return {
          ...this.props.tplsLoaded[form.tplId].tpl,
          pages: _pages.map((p) => {
            return {
              fields: p.fields.map((f) => {
                let val = '';
                switch (f.type) {
                  case 'radio':
                    let selected = f.options.filter((o) => o.nm === f.value);
                    if (selected.length) {
                      val = selected[0].label;
                    }
                    break;
                  case 'checkbox':
                    val = f.options
                      .filter((o) => f.value.includes(o.nm))
                      .map((o) => o.label);
                    break;
                  default:
                    val = f.value;
                    break;
                }

                let field_pkg = {
                  nm: f.nm,
                  label: f.label,
                  type: f.type,
                  value: val,
                };

                if (f.type === 'table') {
                  field_pkg.fields = f.fields.map((tf) => {
                    return {
                      nm: tf.nm,
                      label: tf.label,
                      type: tf.type,
                      row_values: !_.isArray(tf.options)
                        ? tf.row_values
                        : tf.row_values.map((rv) => {
                            if (
                              rv === null ||
                              rv === '' ||
                              tf.type === 'text' ||
                              tf.type === 'date'
                            )
                              return rv;

                            return tf.type === 'radio'
                              ? tf.options.filter((o) => o.nm === rv)[0].label
                              : tf.options
                                  .filter((o) => rv.includes(o.nm))
                                  .map((o) => o.label);
                          }),
                    };
                  });
                }
                return field_pkg;
              }),
            };
          }),
        };
      });

    let pkg = {
      idn: this.props.opsFormRecordsInView.idn,
      extras: this.props.extras || {},
      wos_ids: this.state.work_orders,
      memo: this.state.memoText,
      spawn_tasks: this.state.spawn_tasks,
      account:
        this.props.idnInView.idnDoc.aa[
          parseInt(this.state.selectedHDMSAccountPosition, 10)
        ],
      tpl_ids: this.state.formsListing
        .filter(
          (form) =>
            (this.state.selectedTypeOfForm === 'wheelchair_assessment'
              ? form.code === 'wheelchair_home_assessment'
              : form.code !== 'wheelchair_home_assessment') && form.checked
        )
        .map((f) => f.tplId),
      forms,
    };

    if (
      this.state.selectedTypeOfForm === 'vent' &&
      this.state.selectedTypeOfVisit === 'initial'
    ) {
      pkg.contactInfo = this.state.contactInfo;
    }

    this.props.createOpsFormRecord_ac(pkg);
  }

  render() {
    const {
      classes,
      idnInView,
      opsFormRecordsInView,
      opsFormRecordActivity,
      me,
    } = this.props;
    const { idn } = opsFormRecordsInView;
    if (!idn) return <h1>No idn in opsFormRecordsInView</h1>;
    const formGroupRow = { root: classes.formGroupRoot };
    /*
    const fileUploadInProgress =
      opsFormRecordActivity.outboundFiles.filter(f => f.progressPercent !== 100)
        .length > 0
    const filesSuccessfullyUploaded = opsFormRecordActivity.outboundFiles.filter(
      f => {
        return f.progressPercent === 100
      }
    ).length
    */
    const cardContentRoot = { root: classes.cardContentRoot };

    if (this.props.isVisible === false) {
      return <></>;
    }

    // const tplsLoadedCount = _.keys(this.props.tplsLoaded).length
    let nSectionNumber = 0;
    //   1 + tplsLoadedCount + (this.props.previewTabs.length > 0 ? 1 : 0)

    let allowCreate =
      this.state.formsListing.filter((d) => d.checked).length > 0;

    const moreThan1HdmsAcct = _.get(idnInView.idnDoc, 'aa', []).length > 1;

    return (
      <div>
        <div>
          <strong style={{ fontSize: 12 }}>{idn.replace(/_/g, ' ')}</strong>
        </div>
        <div>
          <span style={{ fontSize: 12 }}>HDMS Account(s):</span>
          <span style={{ fontWeight: 'bold', fontSize: 12, marginRight: 8 }}>
            {_.get(idnInView.idnDoc, 'aa', []).join(',')}
          </span>
        </div>
        {this.state.work_orders.length > 0 ? (
          <div>
            <span style={{ fontSize: 12, marginRight: 8 }}>
              ASSOCIATED WORK ORDER(s):
            </span>
            <span style={{ fontWeight: 'bold', fontSize: 12 }}>
              {this.state.work_orders.join(', ')}
            </span>
          </div>
        ) : (
          <div>
            <span style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>
              WARNING - No work order numbers provided
            </span>
          </div>
        )}

        {this.state.spawn_tasks.length > 0 && (
          <div>
            <span style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>
              Submitting this form will spawn the following task(s):
              {this.state.spawn_tasks.join(', ')}
            </span>
          </div>
        )}

        {opsFormRecordActivity.state === 'inprog' && (
          <List className={classes.root}>
            <ListItem>submitting ....</ListItem>
            <CircularProgress />
          </List>
        )}

        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            <strong>{++nSectionNumber}) Type of Form</strong>
          </FormLabel>
          <FormHelperText>Select the type of form:</FormHelperText>
          <RadioGroup
            classes={formGroupRow}
            onChange={this.onTypeOfFormChange}
            value={this.state.selectedTypeOfForm}>
            {this.state.typesOfForms.map((a) => (
              <FormControlLabel
                labelPlacement="top"
                key={a.code}
                value={a.code}
                disabled={a.disabled}
                label={<strong>{a.lbl}</strong>}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Divider />

        {opsFormRecordActivity.state === 'success' && (
          <div classes={cardContentRoot}>
            <List className={classes.root}>
              <ListItem>
                <Avatar>
                  <DoneOutlineIcon />
                </Avatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    opsFormRecordActivity.state === 'inprog'
                      ? 'Form submission in progress'
                      : 'Form Record #' +
                        opsFormRecordActivity.successPkg.number +
                        ' - Document request successfully submitted by ' +
                        opsFormRecordActivity.successPkg.record.u_id +
                        ' on ' +
                        moment(
                          opsFormRecordActivity.successPkg.record.ts
                        ).format('ddd MM-DD-YYYY hh:mma')
                  }
                  secondary="Status"
                />
              </ListItem>
              <ListItem>
                <Avatar>
                  <AttachmentIcon />
                </Avatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={this.state.formsListing
                    .filter((d) => d.checked)
                    .map((d) => d.lbl)
                    .join(', ')}
                  secondary="Data removed from device storage"
                />
              </ListItem>
              <ListItem>
                <Button
                  onClick={this.resetForm}
                  variant="contained"
                  color="secondary"
                  className={classes.button}>
                  Reset Form
                </Button>
              </ListItem>
            </List>
          </div>
        )}

        {(opsFormRecordActivity.state === 'new' ||
          opsFormRecordActivity.state === 'error') && (
          <div classes={cardContentRoot}>
            {/* 1) Document Templates Being Transmitted */}

            {this.state.selectedTypeOfForm === 'vent' && (
              <>
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label">
                    {++nSectionNumber}) Type of Visit
                  </FormLabel>
                  <FormHelperText>Select the type of visit:</FormHelperText>
                  <RadioGroup
                    classes={formGroupRow}
                    onChange={this.onTypeOfVisitSelected}
                    value={this.state.selectedTypeOfVisit}>
                    {this.state.visitTypes.map((a) => (
                      <FormControlLabel
                        labelPlacement="top"
                        key={a.code}
                        value={a.code}
                        label={a.lbl}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label">
                    {++nSectionNumber}) Type of Therapy
                  </FormLabel>
                  <FormHelperText>Select the type of therapy:</FormHelperText>
                  <RadioGroup
                    classes={formGroupRow}
                    onChange={this.onTypeOfTherapySelected}
                    value={this.state.selectedTypeOfTherapy}>
                    {this.state.therapyTypes.map((a) => (
                      <FormControlLabel
                        labelPlacement="top"
                        key={a.code}
                        value={a.code}
                        label={a.lbl}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label">
                    {++nSectionNumber}) Choose the Equipment Model(s)
                  </FormLabel>
                  <FormHelperText>
                    Check all that apply. If you there is a secondary of the
                    same model as the primary, fill out this form again choosing
                    that secondary after submitting this set of forms.
                  </FormHelperText>
                  <FormGroup classes={formGroupRow}>
                    {this.state.formsListing
                      .filter(
                        (a) =>
                          a.isEquipModel &&
                          a.code !== 'wheelchair_home_assessment'
                      )
                      .map((a) => (
                        <FormControlLabel
                          labelPlacement="top"
                          key={a.code}
                          control={
                            <Checkbox
                              disabled={a.disabled ? true : false}
                              onChange={this.formSelected(a.code)}
                              checked={a.checked}
                              value={a.code}
                            />
                          }
                          label={a.lbl}
                          className={classes.formGroupChild}
                        />
                      ))}
                  </FormGroup>
                </FormControl>

                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label">
                    {++nSectionNumber}) Choose the remaining forms to fill out:
                  </FormLabel>
                  <FormHelperText>
                    This should be auto selected based on your answers from #1
                    and #2 above
                  </FormHelperText>
                  <FormGroup classes={formGroupRow}>
                    {this.state.formsListing
                      .filter(
                        (a) =>
                          true !== a.isEquipModel &&
                          a.code !== 'wheelchair_home_assessment'
                      )
                      .map((a) => (
                        <FormControlLabel
                          labelPlacement="top"
                          key={a.code}
                          control={
                            <Checkbox
                              disabled={a.disabled ? true : false}
                              onChange={this.formSelected(a.code)}
                              checked={a.checked}
                              value={a.code}
                            />
                          }
                          label={a.lbl}
                          className={classes.formGroupChild}
                        />
                      ))}
                  </FormGroup>
                </FormControl>
              </>
            )}

            {this.state.selectedTypeOfForm === 'wheelchair_assessment' && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                <FormLabel component="label">
                  {++nSectionNumber}) Select the assessment form
                </FormLabel>
                <FormHelperText>
                  Form will be loaded after selection is made
                </FormHelperText>
                <FormGroup classes={formGroupRow}>
                  {this.state.formsListing
                    .filter((a) => a.code === 'wheelchair_home_assessment')
                    .map((a) => (
                      <FormControlLabel
                        labelPlacement="top"
                        key={a.code}
                        control={
                          <Checkbox
                            disabled={a.disabled ? true : false}
                            onChange={this.formSelected(a.code)}
                            checked={a.checked}
                            value={a.code}
                          />
                        }
                        label={a.lbl}
                        className={classes.formGroupChild}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            )}

            {_.values(this.props.tplsLoaded)
              .filter((tpl) =>
                this.state.selectedTypeOfForm === 'wheelchair_assessment'
                  ? tpl.tplId === 'wheelchair_home_assessment'
                  : tpl.tplId !== 'wheelchair_home_assessment'
              )
              .sort((a, b) => {
                return this.state.formsListing
                  .filter(
                    (f) =>
                      this.state.selectedTypeOfForm ===
                        'wheelchair_assessment' || f.isEquipModel
                  )
                  .map((f) => f.code)
                  .includes(a.tpl.id)
                  ? -1
                  : 1;
              })
              .map((t, tIdx) => {
                let _pt = this.props.previewTabs.filter(
                  (p) => p.previewTabId === t.tpl.id
                );
                if (_pt.length) _pt = _pt[0];
                else _pt = false;
                return (
                  <ExpansionPanel defaultExpanded={false} key={t.tpl.id}>
                    <ExpansionPanelSummary
                      style={{
                        margin: 0,
                        paddingLeft: 8,
                        backgroundColor: TPL_BG_COLORS[tIdx],
                      }}
                      expandIcon={<ExpandMoreIcon />}>
                      <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>
                        FORM SECTION - {t.tpl.display_name.toUpperCase()}
                      </Typography>
                    </ExpansionPanelSummary>

                    <ExpansionPanelDetails
                      style={{
                        backgroundColor: TPL_BG_COLORS[tIdx],
                        padding: 0,
                      }}>
                      <TemplateForm
                        hideRenderHelpText
                        isPreSaving={
                          this.state.isPreSaving[this.state.selectedTypeOfForm]
                        }
                        uponLocalSave={this.uponLocalSave}
                        backgroundColor={TPL_BG_COLORS[tIdx]}
                        tableRowFieldPrefix={''}
                        noRender
                        sectionNumber={++nSectionNumber}
                        onChangeTplTextFieldValue={
                          this.props.onChangeTplTextFieldValue
                        }
                        onChangeTplCheckboxFieldValue={
                          this.props.onChangeTplCheckboxFieldValue
                        }
                        onChangeTplTextFieldValue_TableRowField={
                          this.props.onChangeTplTextFieldValue_TableRowField
                        }
                        onChangeTplCheckboxFieldValue_TableRowField={
                          this.props.onChangeTplCheckboxFieldValue_TableRowField
                        }
                        tpl={t.tpl}
                        resultingPreviewTab={_pt}
                        me={me}
                        idnInView={idnInView}
                        classes={classes}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                );
              })}

            {this.props.previewTabs.length > 0 && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                <FormLabel component="label">
                  {++nSectionNumber}) Mark the files from your Preview tabs that
                  should be transmitted as part of this request:
                </FormLabel>
                <FormHelperText>Check all that apply.</FormHelperText>
                <FormGroup classes={formGroupRow}>
                  {this.props.previewTabs
                    .filter((p) => true || p.status === 'RENDERED')
                    .map((p, pos) => (
                      <FormControlLabel
                        labelPlacement="top"
                        key={pos}
                        control={
                          <Checkbox
                            onChange={this.props.previewFileSelected(
                              p.previewTabId
                            )}
                            checked={p.checked}
                            value={p.previewTabId}
                          />
                        }
                        label={p.previewTabId}
                      />
                    ))}
                </FormGroup>
              </FormControl>
            )}

            {moreThan1HdmsAcct && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                <FormLabel component="label">
                  {++nSectionNumber}) Patient has more than 1 HDMS Account
                  Number - select applicable:
                </FormLabel>
                <FormHelperText>
                  Make sure the correct account # is selected
                </FormHelperText>
                <RadioGroup
                  classes={formGroupRow}
                  onChange={this.hdmsAccountPositionSelected}
                  value={this.state.selectedHDMSAccountPosition}>
                  {idnInView.idnDoc.aa.map((a, pos) => (
                    <FormControlLabel
                      labelPlacement="top"
                      key={a}
                      value={pos.toString()}
                      label={a}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            {this.state.selectedTypeOfForm === 'vent' &&
              this.state.selectedTypeOfVisit === 'initial' && (
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label" required={false}>
                    {++nSectionNumber}) Contact info:
                  </FormLabel>
                  <InitialVentMetaForm
                    classes={classes}
                    contactInfo={this.state.contactInfo}
                    onChange={this.onContactInfoChange}
                  />
                </FormControl>
              )}

            {this.state.selectedTypeOfForm === 'vent' && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                <FormLabel component="label">
                  {++nSectionNumber}) Visit Comments:
                </FormLabel>
                <FormHelperText error>
                  Notes/comments regarding this vist/encounter
                </FormHelperText>
                <FormGroup classes={formGroupRow}>
                  <TextField
                    onChange={this.onMemoTextChange}
                    value={this.state.memoText}
                    style={{ margin: '8px 8px 8px 0' }}
                    placeholder=""
                    multiline
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormGroup>
              </FormControl>
            )}

            {this.state.selectedTypeOfForm && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                {this.state.finalPresave[this.state.selectedTypeOfForm] ? (
                  <>
                    <FormLabel component="label">
                      {++nSectionNumber}) Submit this Form
                    </FormLabel>
                    <FormHelperText error>
                      YOU MUST CLICK AGAIN TO SUBMIT THE FORM!
                    </FormHelperText>
                  </>
                ) : (
                  <FormLabel component="label">
                    {++nSectionNumber}) Save Form Data to Local Device Storage
                  </FormLabel>
                )}

                {(opsFormRecordActivity.state === 'inprog' ||
                  this.state.isPreSaving[this.state.selectedTypeOfForm]) && (
                  <CircularProgress
                    value={50}
                    color="primary"
                    variant="indeterminate"
                    thickness={4}
                  />
                )}
                {opsFormRecordActivity.state !== 'inprog' && (
                  <>
                    <FormGroup classes={formGroupRow}>
                      <Button
                        disabled={!allowCreate}
                        onClick={this.handleCreateDocRequest}
                        variant="contained"
                        color="primary"
                        className={classes.button}>
                        {this.state.finalPresave[this.state.selectedTypeOfForm]
                          ? 'Submit this Form'
                          : 'Save to Local Device'}
                        <CreateIcon className={classes.rightIcon} />
                      </Button>
                    </FormGroup>
                    <div style={{ clear: 'both' }}></div>
                    <div>
                      {this.state.spawn_tasks.length > 0 && (
                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>
                          Submitting this form will spawn the following task(s):
                          {this.state.spawn_tasks.join(', ')}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </FormControl>
            )}
          </div>
        )}

        <Divider component="hr" />
      </div>
    );
  }
}

OpsForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  idnInView: stateFromStore.idnInView,
  idnAccountInfo: stateFromStore.idnAccountInfo,
  opsFormRecordsInView: stateFromStore.opsFormRecordsInView,
  opsFormRecordActivity: stateFromStore.opsFormRecordActivity,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetOpsFormRecordActivity_ac,
      createOpsFormRecord_ac,
      pre_createOpsFormRecord_ac,
      undo_createOpsFormRecord_ac,
      attachFilesToStore_ac,
      changePage: (path) => {
        return push(path);
      },
      open_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OpsForm))
);
