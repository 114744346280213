import classnames from 'classnames';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {},
  node: {
    padding: '8px 0px',
    borderTop: '2px solid #e0e0e0',
    transition: 'height .5s ease',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  nodeHeader: {
    justifyContent: 'space-between',
  },
  gridHeader_first: {
    justifyContent: 'flex-start',
  },
  gridHeader_mid: {
    justifyContent: 'center',
  },
  gridHeader_last: {
    justifyContent: 'flex-end',
  },
  gridValue: { marginLeft: '6px', maxWidth: '60%' },
  firstNode: {
    borderTop: 'none',
  },
  label: {
    color: '#828282',
  },
  linkValue: {
    color: '#00bcd4',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  noteLabel: {
    color: '#9c27b0',
    cursor: 'pointer',
    marginLeft: '3px',
    width: 'fit-content',
  },
});

const buildOpenMap = (list = []) => {
  const openMap = {};
  list.forEach((item, index) => {
    if (item.grid) {
      item.list.forEach((gridItem, gridIndex) => {
        if (gridItem.children) {
          openMap[item.id + gridIndex] = gridItem.initialOpen || false;
        }
      });
    } else openMap[index] = item.initialOpen || false;
  });
  return openMap;
};

const DynamicAccordion = (props) => {
  const {
    classes: defaultClasses,
    overrideClasses,
    list,
    style,
    after,
  } = props;

  const classes = { ...defaultClasses, ...overrideClasses };

  const [openMap, setOpenMap] = useState(buildOpenMap(list));

  const buildNode = (item, index, gridLoc = false) => {
    const isClickable = Boolean(item.onClick) || Boolean(item.children);
    const isExpanded = openMap[index] && Boolean(item.children);
    return (
      <div
        className={classnames(classes.header, {
          [classes.nodeHeader]: !gridLoc,
          [classes[`gridHeader_${gridLoc}`]]: gridLoc,
        })}>
        <span className={classes.label}>{item.label ? (item.label + ': ') : ""}</span>
        <span
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }

            if (item.children) {
              setOpenMap({ ...openMap, [index]: !isExpanded });
            }
          }}
          className={classnames({
            [classes.gridValue]: Boolean(gridLoc),
            [classes.linkValue]: isClickable,
          })}>
          {item[isExpanded ? 'altValue' : 'value'] || '-'}
        </span>
        {item.sideChildren?.label && (
          <span
            onClick={item.sideChildren.onClick}
            className={classes.noteLabel}>
            {` | ${item.sideChildren.label}`}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      {' '}
      <div className={classes.root} style={style}>
        {list.map((item, index) => {
          const expandedItems = item.grid
            ? item.list
                .map((gridItem, gridIndex) => {
                  const idx = item.id + gridIndex;
                  return openMap[idx] && Boolean(gridItem.children) ? (
                    <div key={'exp-' + idx}>{gridItem.children}</div>
                  ) : (
                    false
                  );
                })
                .filter(Boolean)
            : openMap[index] && Boolean(item.children) && item.children;

          return (
            <div
              key={index}
              className={classnames(classes.node, {
                [classes.firstNode]: index === 0,
              })}>
              {item.grid ? (
                <Grid container>
                  {item.list.map((gridItem, gridIndex) => {
                    return (
                      <Grid
                        item
                        xs={item.spread ? 12 : 12 / item.count}
                        style={
                          item.spread
                            ? {
                                marginBottom: '4px',
                              }
                            : {}
                        }
                        key={item.id + gridIndex}>
                        {buildNode(
                          gridItem,
                          item.id + gridIndex,
                          item.spread
                            ? false
                            : gridIndex === item.count - 1
                            ? 'last'
                            : gridIndex === 0
                            ? 'first'
                            : 'mid'
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                buildNode(item, index)
              )}
              {expandedItems}
            </div>
          );
        })}
      </div>
      {after && after(classes)}
    </>
  );
};

export default withStyles(styles)(DynamicAccordion);
