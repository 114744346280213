import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, withStyles } from '@material-ui/core';

import {
  fetchWorkorders_ac,
  saveWorkorderSearchFilters_ac,
  saveWorkorderSorting_ac,
} from '../../actions/workorders.ac';
import { cleanObject } from '../../util/object';
import ReactSelect from '../common/ReactSelect';
import CustomSelect from '../common/CustomSelect';
import useDebounce from '../../hooks/useDebounce';
import DeliveryTrackTable from './DeliveryTrackTable';
import { decodeWoDisplay } from '../../util/deliveryTrack';
import SuperReactDatePicker from '../common/SuperReactDatePicker';
import endpoints from '../../constants/endpoints';
import {
  SPECIAL_FILERS,
  REQUEST_TYPE,
  SCHEDULE_TYPE,
  PRINT_TYPE,
} from '../../constants/dt';

const defaultSorting = {
  dc_nm: 1, // -1 desc , 1 asc
};

const INITIAL_FILTERS = {
  dc_id: [],
  pcr_id: [],
  method_id: [],
  driver_id: [],
  special_filters: [],
  req_dt_type: 'any',
  req_end_dt: null,
  sched_dt_type: 'un',
  sched_end_dt: null,
  req_dt: new Date(),
  sched_dt: new Date(),
  print_status: 'b',
  search: '',
};

const Dt = (props) => {
  const {
    classes,
    saveSorting,
    workordersList,
    fetchWorkorders,
    saveWorkorderSearchFilters,
  } = props;
  console.log(props, 'porps');
  const { isFetching, workorders } = workordersList;

  const [pcr, setPcr] = useState([]);
  const [method, setMethod] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [location, setLocation] = useState([]);
  const [sortData, setSortData] = useState(defaultSorting);
  const [filters, setFilters] = useState({
    ...INITIAL_FILTERS,
  });
  const [isSavingToGoogleSheet, setSavingToGoogleSheet] = useState(false);

  const showeDtFilter = ['on', 'on_or_before', 'on_or_after', 'between'];

  const fetchWorkOrderList = () => {
    saveSorting({ sorting: sortData });

    fetchWorkorders();
  };

  const handlePageChange = (newPage) => {
    fetchWorkorders(newPage - 1);
  };

  const handleResetSorting = () => {
    setSortData(defaultSorting);
    saveSorting({ sorting: sortData });
  };

  useDebounce(fetchWorkOrderList, 1000, [sortData]);

  const applyFilters = () => {
    const page = 0;

    fetchWorkorders(page);
  };

  const handleFilterFieldChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({ ...INITIAL_FILTERS });
  };

  const handleDateChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const fetchLocation = async () => {
    try {
      const { place } = await window.sch.get(endpoints.location);
      const data = place.map((loc) => ({ value: loc.dc_id, label: loc.dc_nm }));
      setLocation(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMethod = async () => {
    try {
      const { rr } = await window.sch.get(endpoints.method);
      let data = rr.map((method) => ({
        label: method.label,
        value: parseInt(method.value),
      }));
      setMethod(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPcr = async () => {
    try {
      const { rr } = await window.sch.get(endpoints.pcr);
      const data = rr.map((pcr) => ({
        value: pcr.hdms_id,
        label: pcr.nm,
      }));
      setPcr(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { rr } = await window.sch.get(endpoints.drivers);
      const data = rr.map((driver) => ({
        value: driver.driver_id,
        label: driver.driver_nm,
      }));
      setDrivers(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLocation();
    fetchMethod();
    fetchPcr();
    fetchDrivers();
  }, []);

  const decodeSearchValues = () => {
    if (!filters.search) return '';

    const splitVal = filters.search
      .split(/[, ]+/)
      .map((val) => val.trim())
      .filter((val) => val.length > 0);
    return splitVal.map((val) => decodeWoDisplay(val));
  };

  useEffect(() => {
    const search = decodeSearchValues();
    const cleanfilterparams = cleanObject({ ...filters, search });

    saveWorkorderSearchFilters(cleanfilterparams);
  }, [filters, saveWorkorderSearchFilters]);

  const saveToGoogleSheet = () => {
    setSavingToGoogleSheet(true);
    const path = '/api/wo/list';
    return window.sch
      .post(path, { ...filters, to_google_sheets: true })
      .then((result) => {
        setSavingToGoogleSheet(false);
        if (result.error) {
          throw new Error(result.error);
        }
        window.open(result.sheet.spreadsheetUrl, result.sheet.properties.title);
      })
      .catch((error) => {
        setSavingToGoogleSheet(false);
        console.error(error);
        return this.props.open_snack_ac({
          variant: 'error',
          message:
            'Failed creating google sheet ' + (typeof error.error === 'string')
              ? error.error
              : '',
        });
      });
  };

  return (
    <>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ padding: '20px 0 0 20px', alignItems: 'end' }}>
        <Grid
          container
          spacing={16}
          alignItems="center"
          style={{ padding: '10px' }}>
          <Grid item xs={2}>
            <CustomSelect
              name="print_status"
              options={PRINT_TYPE}
              label="Print Status"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.print_status}
            />
          </Grid>

          <Grid item xs={2}>
            <CustomSelect
              name="sched_dt_type"
              options={SCHEDULE_TYPE}
              label="Schedule Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.sched_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.sched_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_dt', date)}
                customInput={
                  <TextField margin="dense" label="Scheduled From" />
                }
              />
            </Grid>
          )}

          {filters.sched_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_end_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_end_dt', date)}
                customInput={<TextField margin="dense" label="Scheduled To" />}
              />
            </Grid>
          )}

          <Grid item xs={2}>
            <CustomSelect
              name="req_dt_type"
              options={REQUEST_TYPE}
              label="Request Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.req_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.req_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_dt', date)}
                customInput={<TextField margin="dense" label="Request From" />}
              />
            </Grid>
          )}

          {filters.req_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_end_dt || new Date()}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_end_dt', date)}
                customInput={<TextField margin="dense" label="Request To" />}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="special_filters"
            label="Custom Filters"
            options={SPECIAL_FILERS}
            placeholder="Select filters"
            value={filters.special_filters}
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="dc_id"
            label="Location"
            options={location}
            value={filters.dc_id}
            placeholder="Select Location"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="method_id"
            options={method}
            label="Suggested Method"
            value={filters.method_id}
            placeholder="Select Method"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="driver_id"
            options={drivers}
            label="Assigned Drivers"
            value={filters.driver_id}
            placeholder="Select Drivers"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            label="PCR"
            name="pcr_id"
            options={pcr}
            value={filters.pcr_id}
            placeholder="Select PCR"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ paddingLeft: '20px', alignItems: 'end' }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="search"
            name="search"
            margin="dense"
            label="Accounts or WO"
            palceholder="Accounts or WO"
            value={filters.search}
            disabled={isFetching}
            className={classes.account}
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>

        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={applyFilters}
            disabled={isFetching}>
            Show Records
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleClearFilters}
            disabled={isFetching}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={saveToGoogleSheet}
            disabled={
              isSavingToGoogleSheet || isFetching || !workorders?.wos?.length
            }>
            Save to Googlesheet
          </Button>
        </Grid>
      </Grid>

      <DeliveryTrackTable
        baseStyle={{ height: '65vh' }}
        sortData={sortData}
        isFetching={isFetching}
        setSortData={setSortData}
        workorders={workorders}
        handlePageChange={handlePageChange}
        handleResetSorting={handleResetSorting}
      />
    </>
  );
};

const mapStateToProps = (stateFromStore) => ({
  workordersList: stateFromStore.workorders,
  workorderSorting: stateFromStore.saveWorkorderSorting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchWorkorders: fetchWorkorders_ac,
      saveSorting: saveWorkorderSorting_ac,
      saveWorkorderSearchFilters: saveWorkorderSearchFilters_ac,
    },
    dispatch
  );
};

const styles = () => ({
  account: {
    marginBottom: 0,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dt));
