const WO_KEY_OPTIONS = [
  { value: '_id', label: 'Workorder ID' },
  { value: 'tz_offset', label: 'Time Zone Offset' },
  { value: 'last_updated_dt', label: 'Last Updated Date' },
  { value: 'sched_dt', label: 'Scheduled Date' },
  { value: 'req_dt', label: 'Requested Date' },
  { value: 'cancel_dt', label: 'Cancellation Date' },
  { value: 'lastprint_dt', label: 'Last Printed Date' },
  { value: 'times_printed', label: 'Times Printed' },
  { value: 'driver_id', label: 'Driver ID' },
  { value: 'dc_id', label: 'DC ID' },
  { value: 'orig_dc_id', label: 'Original DC ID' },
  { value: 'method_id', label: 'Method ID' },
  { value: 'orig_method_id', label: 'Original Method ID' },
  { value: 'acct', label: 'Account' },
  { value: 'member', label: 'Member' },
  { value: 'ps_id', label: 'PS ID' },
  { value: 'salesrep_id', label: 'Sales Representative ID' },
  { value: 'salesrep_nm', label: 'Sales Representative Name' },
  { value: 'pcr_id', label: 'PCR ID' },
  { value: 'mrn', label: 'MRN' },
  { value: 'indicator', label: 'Indicator' },
  { value: 'patient_nm', label: 'Patient Name' },
  { value: 'addr_1', label: 'Address Line 1' },
  { value: 'addr_2', label: 'Address Line 2' },
  { value: 'city', label: 'City' },
  { value: 'state', label: 'State' },
  { value: 'zip', label: 'ZIP Code' },
  { value: 'phone', label: 'Phone' },
  { value: 'applied_phone', label: 'Applied Phone' },
  { value: 'deliv_note', label: 'Delivery Note' },
  { value: 'status_note', label: 'Status Note' },
  { value: 'time_block', label: 'Time Block' },
  { value: 'req_time_block_id', label: 'Requested Time Block ID' },
  { value: 'sched_time_block_id', label: 'Scheduled Time Block ID' },
  { value: 'last_update_user', label: 'Last Update User' },
  { value: 'last_updated_by', label: 'Last Updated By' },
  { value: 'patient_dob', label: 'Patient Date of Birth' },
  { value: 'pcr_nm', label: 'PCR Name' },
  { value: 'dx1', label: 'Diagnosis 1' },
  { value: 'dx2', label: 'Diagnosis 2' },
  { value: 'dx3', label: 'Diagnosis 3' },
  { value: 'dx4', label: 'Diagnosis 4' },
  { value: 'email_address', label: 'Email Address' },
  { value: 'bill_addr_1', label: 'Billing Address Line 1' },
  { value: 'bill_addr_2', label: 'Billing Address Line 2' },
  { value: 'bill_city', label: 'Billing City' },
  { value: 'bill_state', label: 'Billing State' },
  { value: 'bill_zip', label: 'Billing ZIP Code' },
  { value: 'bill_phone', label: 'Billing Phone' },
  { value: 'taken_dt', label: 'Taken Date' },
  { value: 'ship_phone', label: 'Shipping Phone' },
  { value: 'bill_phone_orig', label: 'Billing Phone Original' },
  { value: 'auth_phone', label: 'Authorization Phone' },
  { value: 'alt_phone', label: 'Alternate Phone' },
  { value: 'stub', label: 'Stub' },
  { value: 'last_updated_dt_utc', label: 'Last Updated Date' },
  { value: 'sched_dt_utc', label: 'Scheduled Date' },
  { value: 'req_dt_utc', label: 'Requested Date' },
  { value: 'cancel_dt_utc', label: 'Cancellation Date' },
  { value: 'lastprint_dt_utc', label: 'Last Printed Date' },
  { value: 'taken_dt_utc', label: 'Taken Date' },
  { value: 'df_url', label: 'DF URL' },
  { value: 'city_ref', label: 'City Reference' },
  { value: 'region', label: 'Region' },
  { value: 'ts', label: 'Timestamp' },
  { value: 'wo_types', label: 'Work Order Types' },
  { value: 'has_purch_rent', label: 'Has Purchase Rent' },
  { value: 'has_conc', label: 'Has Concentrator' },
  { value: 'staged_ts', label: 'Staged Timestamp' },
  { value: 'geo', label: 'Geolocation' },
  { value: 'payername', label: 'Payer Name' },
  { value: 'proc_group', label: 'Procedure Group' },
  { value: 'dt_env', label: 'Environment' },
].sort((a, b) => a.label.localeCompare(b.label));

export const OPTIONS = {
  WO: WO_KEY_OPTIONS,
  PT: [],
};
