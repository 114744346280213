import React, { useState } from 'react';
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ChatBubbleOutline, ExitToApp } from '@material-ui/icons';

import { dateFormat } from '../../util/date';
import CustomTable from '../common/CustomTable';
import { wo_display } from '../../util/deliveryTrack';
import PaginationWithReset from '../IndexingAdmin/common/PaginationWithReset';

const NoteChip = ({ text, icon: Icon, onClick }) => {
  return (
    <Chip
      label={
        <Grid
          style={{
            height: 21,
          }}>
          <Typography
            inline
            style={{
              paddingRight: 5,
              color: 'white',
              fontWeight: 'bold',
            }}>
            {text}
          </Typography>
          <Icon fontSize="inherit" />
        </Grid>
      }
      style={{
        width: 40,
        height: 18,
        fontSize: 13,
        marginLeft: 5,
        color: 'white',
        background: '#F8AC59',
      }}
      onClick={onClick}
    />
  );
};

const LineTypeChip = ({ text, icon: Icon, bgColor, style, onClick }) => {
  return (
    <Chip
      label={
        <Grid
          style={{
            height: 24,
          }}>
          <Icon fontSize="inherit" />
          <Typography
            inline
            style={{
              paddingLeft: 5,
              color: 'white',
              fontWeight: 'bold',
            }}>
            {text}
          </Typography>
        </Grid>
      }
      style={{
        width: 40,
        height: 18,
        fontSize: 14,
        marginLeft: 5,
        color: 'white',
        background: bgColor,
        ...style,
      }}
      onClick={onClick}
    />
  );
};

const OrderList = ({ items }) => {
  return (
    <List component="ol">
      {items.map((item, index) => (
        <ListItem key={index} component="li" style={{ padding: 0 }}>
          <ListItemText
            primary={
              <>
                <Typography inline variant="subtitle2">
                  {`${index + 1}. ${item.product_desc} ${item.qty_ordered}`}
                </Typography>

                <Typography
                  inline
                  variant="subtitle2"
                  style={{ fontWeight: 'bold' }}>
                  {`${item.uom} ${item.wo_type_str}`}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const Thread = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div key={index}>
          <Typography variant="subtitle2">{item.txt}</Typography>
          <Typography
            variant="caption"
            style={{
              fontSize: 11,
              color: '#777777',
            }}>{`- ${item.u_id} ${dateFormat(
            item.ts,
            'MMM D h:mmA'
          )}`}</Typography>
        </div>
      ))}
    </>
  );
};

const DeliveryTrackTable = (props) => {
  const {
    classes,
    workorders,
    isFetching,
    handleResetSorting,
    handlePageChange,
    sortData,
    setSortData,
    baseStyle,
    sortSupport,
  } = props;

  const [showThread, setShowThread] = useState({});
  const [showLineItems, setShowLineItems] = useState({});

  const columns = [
    {
      title: 'Location',
      dataIndex: 'dc_nm',
      key: 'dc_nm',
      width: 80,
      sortable: true,
      render: (data) => (
        <>
          {data.dc_nm}
          <br />
          <Typography style={{ color: '#428bca' }}>
            {`Staged:${dateFormat(data.staged_ts, 'MMM D h:mmA')}`}
          </Typography>
          <Typography
            style={{
              fontWeight: 'bold',
              color: 'red',
              textTransform: 'capitalize',
            }}>
            {`Not Your DC!!!`}
          </Typography>
        </>
      ),
    },
    {
      title: (
        <>
          <Typography inline>WO# Patient Acct</Typography>
          <LineTypeChip
            text="Delivery"
            icon={ExitToApp}
            bgColor="#1d84c6"
            style={{ width: 80 }}
          />
          <LineTypeChip
            text="Pickup"
            icon={ExitToApp}
            bgColor="#24c6c8"
            style={{ width: 80 }}
          />
        </>
      ),
      dataIndex: '_id',
      key: '_id',
      width: 130,
      render: (data) => {
        return (
          <>
            <Typography inline style={{ fontWeight: 'bold', marginRight: 3 }}>
              {wo_display(data._id)}
            </Typography>
            <Typography inline style={{ marginRight: 3 }}>
              {data.patient_nm}
            </Typography>
            <Typography inline style={{ color: '#428bca' }}>
              {data.acct}
            </Typography>
            -
            <Typography inline style={{ color: '#428bca' }} component="div">
              View DF
              <NoteChip
                text={data.thread ? data.thread.length : '+'}
                icon={ChatBubbleOutline}
                onClick={() => showThreads(data._id)}
              />
            </Typography>
            {data.wo_types?.Delivery && (
              <LineTypeChip
                icon={ExitToApp}
                bgColor="#1d84c6"
                text={data.wo_types.Delivery}
                onClick={() => showLines(data._id)}
              />
            )}
            {data.wo_types?.Pickup && (
              <LineTypeChip
                icon={ExitToApp}
                bgColor="#24c6c8"
                text={data.wo_types.Pickup}
                onClick={() => showLines(data._id)}
              />
            )}
            <br />
            <Typography
              inline
              style={{ marginRight: 3 }}>{`[PS: ${data.ps_id}]`}</Typography>
            <Typography
              inline
              style={{
                fontWeight: 'bold',
                color: '#428bca',
              }}>{`${data.pcr_nm} ${data.phone}`}</Typography>
            {showThread[data._id] && data.thread && (
              <Thread items={data.thread} />
            )}
            {showLineItems[data._id] && <OrderList items={data.lines} />}
          </>
        );
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 35,
      render: (data) => (
        <>
          {/*data.addr_1*/}
          {/* <br /> */}
          {`${data.city} ${data.state}`}
          <br />
          {`ph: ${data.phone}`}
        </>
      ),
    },
    // {
    //   title: 'Milkrun Grouping',
    //   dataIndex: 'group',
    //   key: 'group',
    //   render: (data) => <>unknown</>,
    // },
    {
      title: 'Delivery Method',
      dataIndex: 'dmethod',
      key: 'dmethod',
      render: (data) => <>{data.method_nm}</>,
    },
    {
      title: 'Assigned to',
      dataIndex: 'assigned',
      key: 'assigned',
      render: (data) => <>{data.driver_nm}</>,
    },
    {
      title: 'Request Date',
      dataIndex: 'req_dt_utc',
      key: 'req_dt_utc',
      sortable: true,
      render: (data) => <>{dateFormat(data.req_dt_utc)}</>,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'sched_dt_utc',
      key: 'sched_dt',
      sortable: true,
      render: (data) => <>{dateFormat(data.sched_dt_utc)}</>,
    },
    {
      title: 'Status',
      dataIndex: 'print_status',
      key: 'print_status',
      render: (data) => {
        let status_msg = '';
        if (data.trip) {
          status_msg = data.trip.status;
        }

        return (
          <>
            <div>
              <strong>{status_msg}</strong>
              <br />
              {data.print_status === 'y' ? 'Printed' : 'UnPrinted'}
              <br />
              {dateFormat(data.lastprint_dt_utc)}
            </div>
          </>
        );
      },
    },
  ];

  const showLines = (rowId) => {
    setShowLineItems((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
  const showThreads = (rowId) => {
    setShowThread((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  return (
    <Grid>
      <Grid style={{ padding: '20px 0 5px 20px' }}>
        {!!workorders?.pagination?.totalRecords && (
          <PaginationWithReset
            data={workorders?.pagination}
            isFetching={isFetching}
            handleResetSorting={handleResetSorting}
            handlePageChange={handlePageChange}
            sortSupport={sortSupport}
          />
        )}
      </Grid>

      <CustomTable
        classes={classes}
        columns={columns}
        sortData={sortData}
        baseStyle={baseStyle}
        data={workorders?.wos}
        isLoading={isFetching}
        backgroundColor={false}
        setSortData={setSortData}
        error={{ isError: 'error', message: 'There is no work orders' }}
      />
    </Grid>
  );
};

const styles = () => ({
  root: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
    },
  },
  table: {
    '& th': {
      padding: 10,
    },
    '& td': {
      padding: 10,
    },
  },
});

export default withStyles(styles)(DeliveryTrackTable);
