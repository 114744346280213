import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';

import { dateFormat } from '../../util/date';
import endpoints from '../../constants/endpoints';
import DeliveryTrackTable from './DeliveryTrackTable';

const baseStyle = { height: '80vh' };

const initialFilters = {
  req_dt_type: 'any',
  req_dt: dateFormat(new Date()),
  sched_dt: dateFormat(new Date()),
  sched_dt_type: 'on',
  print_status: 'b',
  page: 0,
};

const SummaryDetail = (props) => {
  const { location } = props;
  const history = useHistory();

  const [data, setData] = useState({});
  const [sortData, setSortData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [filteredData, setFilteredData] = useState(initialFilters);

  const fetchRouteSummaryDetail = async (filters) => {
    setIsLoading(true);
    try {
      const { pkg } = await window.sch.post(
        endpoints.route_summary_detail,
        filters
      );
      setData(pkg);
    } catch (err) {
      console.error('Error fetching route summary details:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state?.bkt_nm) {
      const bkt_nm = location.state.bkt_nm.toUpperCase().split(' ').join('_');
      const m_nm = location.state.m_nm;
      const sched_dt = location.state.sched_dt;
      const updatedFilters = { ...initialFilters, m_nm, bkt_nm, sched_dt };

      setFilteredData(updatedFilters);
      fetchRouteSummaryDetail(updatedFilters);
    }
  }, [location.state]);

  const handlePageChange = (newPage) => {
    const updatedFilters = { ...filteredData, page: newPage - 1 };
    setFilteredData(updatedFilters);
    fetchRouteSummaryDetail(updatedFilters);
  };

  const handleBack = () => {
    history.goBack();
  };

  if (!location.state?.bkt_nm) {
    return (
      <Grid style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.history.push('/dt_summary')}>
          Back to Summary
        </Button>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid container alignItems="center" style={{ padding: '10px 0 0 15px' }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBack}
            size="small">
            Back to Summary
          </Button>
        </Grid>

        <Grid item style={{ paddingLeft: 10, fontWeight: 500 }}>
          <Typography variant="subtitle1" inline>
            Work Orders in Status:
          </Typography>
          <Typography variant="subtitle1" inline>
            {` ${location.state?.m_nm} - ${location.state?.bkt_nm}`}
          </Typography>
        </Grid>
      </Grid>
      <DeliveryTrackTable
        sortData={sortData}
        isFetching={isLoading}
        setSortData={setSortData}
        workorders={data}
        baseStyle={baseStyle}
        sortSupport={false}
        handlePageChange={handlePageChange}
      />
    </Grid>
  );
};

export default SummaryDetail;
