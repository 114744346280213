import { connect } from 'react-redux';
import React, { useState } from 'react';
import {
  Chip,
  Button,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';

import AsyncSelect from './AsyncSelect';
import { cleanObject } from '../../util/object';
import { open_snack_ac } from '../../actions/snack.ac';
import { createAnnouncement } from '../../services/announcement';

const priority = [
  { label: 'Default', value: 'default' },
  { label: 'Normal', value: 'normal' },
  { label: 'High', value: 'high' },
];
const INITIAL_FORM_DATA = {
  shouldAck: false,
  priority: 'default',
  triggerPushNotification: false,
};

const roles = [
  'admin',
  'inactive',
  'dispatcher',
  'oncall',
  'courier',
  'no_phone',
  'driver',
];

const AnnouncementForm = (props) => {
  const { open_snack_ac } = props;

  const [errors, setErrors] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'roles') {
      setMenuOpen(false);
    }
  };
  const validateForm = () => {
    const requiredFields = {
      title: 'Title is required',
      description: 'Description is required',
    };

    const newErrors = Object.entries(requiredFields).reduce(
      (errors, [field, errorMessage]) => {
        if (!formData[field]) {
          errors[field] = errorMessage;
        }
        return errors;
      },
      {}
    );

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      setIsSubmitting(true);
      const cleanData = cleanObject(formData);
      await createAnnouncement(cleanData);
      open_snack_ac({
        variant: 'success',
        message: "Announcements created sucessfully'",
      });
      props.history.push('/announcements');
    } catch (err) {
      setIsSubmitting(false);
      open_snack_ac({
        variant: 'error',
        message: err.message,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteRole = (role) => {
    const newRoles = formData.roles.filter((r) => r !== role);
    setFormData({
      ...formData,
      roles: newRoles,
    });
  };

  return (
    <div style={{ maxWidth: 600, padding: 16 }}>
      <Typography variant="h4" gutterBottom>
        Announcement Form
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Please fill out the form below
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth margin="dense">
          <TextField
            name="title"
            label="Title*"
            variant="outlined"
            value={formData.title || ''}
            error={!!errors.title}
            onChange={handleChange}
            disabled={isSubmitting}
            helperText={errors.title}
          />
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            name="subtitle"
            label="Subtitle"
            variant="outlined"
            value={formData.subtitle || ''}
            disabled={isSubmitting}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            select
            name="priority"
            label="Priority"
            value={formData.priority}
            onChange={handleChange}
            variant="outlined"
            disabled={isSubmitting}>
            {priority.map(({ label, value }, idx) => (
              <MenuItem value={value} key={idx}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            rows={2}
            multiline
            name="description"
            label="Description*"
            variant="outlined"
            value={formData.description || ''}
            error={!!errors.description}
            onChange={handleChange}
            disabled={isSubmitting}
            helperText={errors.description}
          />
        </FormControl>

        <FormControl fullWidth margin="dense">
          <AsyncSelect
            label="User Search"
            dataKey="results"
            apiEndpoint="/api/users"
            paramName="searchStr"
            disabled={isSubmitting}
            resultMap={(data) => ({ label: data.nm, value: data._id })}
            onSelect={(data) => {
              const event = {
                target: {
                  name: 'userId',
                  value: data.value,
                },
              };
              handleChange(event);
            }}
            baseParams={{
              rawQuery: {
                'props.driver_id': {
                  $exists: true,
                },
              },
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="dense">
          <TextField
            select
            name="roles"
            label="Roles"
            onChange={handleChange}
            variant="outlined"
            disabled={isSubmitting}
            SelectProps={{
              value: formData.roles || [],
              multiple: true,
              renderValue: (selected) =>
                selected.map((value, idx) => (
                  <Chip
                    key={idx}
                    label={value}
                    onDelete={() => handleDeleteRole(value)}
                  />
                )),
              open: menuOpen,
              onOpen: () => setMenuOpen(true),
              onClose: () => setMenuOpen(false),
            }}>
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                name="shouldAck"
                color="primary"
                disabled={isSubmitting}
                checked={formData.shouldAck}
                onChange={handleChange}
              />
            }
            label="Should Acknowledge"
          />
        </FormControl>

        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                disabled={isSubmitting}
                name="triggerPushNotification"
                checked={formData.triggerPushNotification}
                onChange={handleChange}
              />
            }
            label="Trigger Push Notification"
          />
        </FormControl>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          fullWidth>
          Create
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = {
  open_snack_ac,
};

export default connect(null, mapDispatchToProps)(AnnouncementForm);
