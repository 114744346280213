import { Button } from '@material-ui/core';
import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
// import { CgArrowsMergeAltV, CgArrowsShrinkV } from 'react-icons/cg';
import moment from 'moment';
import Loader from '../../Shared/Loader';
import Timeline from '../../WorkUnits/common/HorizontalTimeline/HorizontalTimeline';
import { fetchWorkunitOrderStatus } from './utils';

const gen_tracking_url = (carrier, tracking) => {
  //convert variations of FEDEX to FEDEX (examples: Fed Ex, FedEx, FedEx Express, etc.)
  if (carrier.match(/fed.*ex/i)) {
    carrier = 'FEDEX';
  } else if (carrier.match(/ups/i)) {
    carrier = 'UPS';
  }

  switch (carrier) {
    case 'UPS':
      return `https://www.ups.com/track?loc=en_US&tracknum=${tracking}`;
    case 'FEDEX':
      return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${tracking}`;
    case 'GSO':
      return `https://www.gso.com/Tracking`;
    default:
      return '#';
  }
};

const Shipment = (props) => {
  const { s, idx } = props;
  let url = gen_tracking_url(s.c, s.tn);
  return (
    <div
      key={idx}
      style={{
        marginBottom: '6px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
      }}>
      <div
        style={{
          marginRight: '12px',
          color: 'chocolate',
          fontWeight: 400,
          fontSize: '12px',
        }}>
        {s.c} {s.tn}
      </div>
      <div
        style={{
          color: '#0078d4',
          cursor: 'pointer',
          textDecoration: 'underline',
          textDecorationColor: '#0078d4',
        }}
        title={url}
        onClick={() => {
          window.open(url, '_blank');
        }}>
        {truncate(url, { length: 50 })}
      </div>
      <LineItems lines={s.lines} />
    </div>
  );
};

const LineItems = (props) => {
  const { lines } = props;
  return (
    <div
      style={{
        marginBottom: '6px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
      }}>
      <ol>
        {lines &&
          lines.map((line, idx) => (
            <li key={idx}>
              <span
                style={{ color: '#000', fontWeight: 400, fontSize: '12px' }}>
                [QTY {line.qty_ordered} {line.uom}]
              </span>
              <span
                style={{
                  marginLeft: '4px',
                  color: '#303f9f',
                  fontWeight: 400,
                  fontSize: '12px',
                }}>
                {line.item || line.product_desc} {line.wo_type_str || ''}
              </span>
            </li>
          ))}
      </ol>
    </div>
  );
};

const EtaLog = (props) => {
  const { items } = props;
  return (
    <div
      style={{
        marginBottom: '6px',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        flexDirection: 'column',
      }}>
      <ol>
        {items &&
          items.map((item, idx) => (
            <li key={idx}>
              <span
                style={{ color: '#303f9f', fontWeight: 400, fontSize: '12px' }}>
                {moment(item.ts).format('MM-DD-YYYY hh:mm A')}
                {item.u_id} changed ETA to: {item.update_sched_time}
              </span>
              <br />
              Reason:{' '}
              <span
                style={{ color: '#000', fontWeight: 400, fontSize: '12px' }}>
                {item.reason}
              </span>
            </li>
          ))}
      </ol>
    </div>
  );
};

const OrderStatus = (props) => {
  const { workunitId, accordion = false } = props;
  const [isLoadingOrderStatus, setIsLoadingOrderStatus] = useState(false);
  const [timelines, setTimelines] = useState(props.snapshot_timelines || null);
  const [pendingReasons, setPendingReasons] = useState(null);

  const [isStatusViewOpen, setIsStatusViewOpen] = useState(
    accordion ? false : true
  );

  const [isTimelineDetailOpen, setisTimelineDetailOpen] = useState(false);

  useEffect(() => {
    if (workunitId && isStatusViewOpen && timelines === null) {
      fetchWorkunitOrderStatus(workunitId, {
        onSuccess: (data) => {
          setTimelines(data?.timelines || []);
          setPendingReasons(data?.pending_reasons || []);
        },
        onError: () => {},
        after: () => {
          setIsLoadingOrderStatus(false);
        },
        before: () => {
          setIsLoadingOrderStatus(true);
        },
      });
    }
  }, [workunitId, isStatusViewOpen, timelines]);

  // const timeLineSummaries = useMemo(() => {
  //   if (timelines) {
  //     return timelines.map((tl) => ({
  //       label: tl.unit_lbl || 'Unknown',
  //       value: tl.states.find((st) => st.active)?.lbl || '',
  //     }));
  //   }
  //   return null;
  // }, [timelines]);
  if (timelines === null) {
    return <Loader type="circular" align="center" />;
  }

  return (
    <div style={props.style}>
      {accordion ? (
        <div>
          <span
            style={{ fontSize: '14px', marginRight: '6px', fontWeight: 500 }}>
            Order Status:
          </span>
          <span
            style={{
              cursor: 'pointer',
              color: '#0078d4',
              fontSize: '12px',
            }}
            onClick={() => {
              setIsStatusViewOpen(!isStatusViewOpen);
            }}>
            {isStatusViewOpen ? 'Hide' : 'View'}
          </span>
        </div>
      ) : null}
      {isStatusViewOpen && (
        <div>
          {isLoadingOrderStatus ? (
            <Loader type="circular" align="center" />
          ) : (
            <div>
              {pendingReasons && pendingReasons.length > 0 && (
                <div style={{ fontSize: 12, paddingBottom: 4 }}>
                  <span>Pending Reasons:</span>
                  <span style={{ marginRight: 8 }}>
                    {pendingReasons.join(', ')}
                  </span>
                </div>
              )}
              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  alignItems: 'center',
                }}>
                <span
                  style={{
                    marginBottom: '10px',
                    cursor: 'pointer',
                    color: '#0078d4',
                  }}
                  onClick={() => {
                    setisTimelineDetailOpen(!isTimelineDetailOpen);
                  }}>
                  {isTimelineDetailOpen ? (
                    <div>
                      <span style={{ fontSize: '14px' }}>{'Hide'}</span>
                      <CgArrowsMergeAltV
                        style={{
                          marginLeft: '4px',
                          height: 20,
                          width: 20,
                          color: '#9c27b0',
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span style={{ fontSize: '14px' }}>{'Expand'}</span>
                      <CgArrowsShrinkV
                        style={{
                          marginLeft: '4px',
                          height: 20,
                          width: 20,
                          color: '#9c27b0',
                        }}
                      />
                    </div>
                  )}
                </span>
              </div> */}
              {timelines && timelines?.length ? (
                <div>
                  {timelines.map((tml, tmi) => {
                    return (
                      <div
                        key={tmi}
                        style={{
                          marginBottom: '10px',
                          border: '1px solid grey',
                          borderRadius: '4px',
                          fontSize: '12px',
                          padding: '8px 10px',
                        }}>
                        <span style={{ marginRight: '12px', fontWeight: 500 }}>
                          {tml.key.toUpperCase().substr(0, 1) + tml._id}
                        </span>

                        {(tml.eta_change_log?.length !== 0 ||
                          tml.shipments?.length !== 0 ||
                          tml.lines?.length !== 0) && (
                          <Button
                            style={{ fontSize: '12px', fontWeight: 500 }}
                            onClick={() => {
                              setisTimelineDetailOpen(!isTimelineDetailOpen);
                            }}>
                            {isTimelineDetailOpen
                              ? 'Hide Details'
                              : 'View Details'}
                          </Button>
                        )}
                        <div
                          style={{
                            padding: '12px 0px',
                          }}>
                          <Timeline
                            condensed
                            items={tml.timelinePkg[tml.order_type].steps || []}
                          />

                          {isTimelineDetailOpen && tml.shipments?.length !== 0 && (
                            <div
                              style={{ marginTop: '8px', paddingLeft: '12px' }}>
                              {tml.shipments.map((s, idx) => (
                                <Shipment s={s} key={idx} idx={idx} />
                              ))}
                            </div>
                          )}
                          {/* {isTimelineDetailOpen &&
                            tml.shipments?.length === 0 && (
                              <LineItems lines={tml.lines} />
                            )} */}
                          {isTimelineDetailOpen &&
                            tml.eta_change_log?.length !== 0 && (
                              <EtaLog items={tml.eta_change_log} />
                            )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <span
                  style={{
                    color: 'grey',
                    fontSize: '12px',
                    marginTop: '12px',
                  }}>
                  No order status timelines found.
                </span>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderStatus;
