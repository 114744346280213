import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Warning from '@material-ui/icons/WarningRounded';
import { Paper, Grid, TextField, Button } from '@material-ui/core';

import { dateFormat } from '../../util/date';
import { cleanObject } from '../../util/object';

import CustomTable from '../common/CustomTable';
import CustomSelect from '../common/CustomSelect';
import ReactSelect from '../common/ReactSelect';
import SuperReactDatePicker from '../common/SuperReactDatePicker';
import { REQUEST_TYPE, SCHEDULE_TYPE, PRINT_TYPE } from '../../constants/dt';

const styles = () => ({
  status: {
    color: 'white',
    borderRadius: '4px',
    padding: '5px 10px',
    margin: '2px 0',
    display: 'inline-block',
  },
  statusCurrent: {
    backgroundColor: '#ffcc80',
  },
  statusError: {
    backgroundColor: '#ef5350',
  },
  statusSuccess: {
    backgroundColor: '#1c84c6',
  },
  statusDefault: {
    backgroundColor: '#efefef',
    color: 'black',
  },
  table: {
    '& th': {
      padding: 10,
    },
    '& td': {
      padding: 10,
    },
  },
  statusVal: {
    marginLeft: '4px',
    fontWeight: 'bold',
    fontSize: 12,
  },
  icon: { fontSize: 16, paddingBottom: 2 },
});

const showeDtFilter = ['on', 'on_or_before', 'on_or_after', 'between'];

const namesToIncludeInFirstSection = [
  'VEHICLE',
  'RESP-SCH LOCATION',
  'RESP-TELEHEALTH',
  'RESP_HOME',
  'ON-CALL',
];

const namesToIncludeInSecondSection = [
  'COURIER',
  'WALK-IN',
  'CONSIGN',
  'GSO',
  'UPS',
];

function StatusCell({ classes, onClick, value, type, showIcon = true, style }) {
  let statusClass = '';
  let Icon;

  switch (type) {
    case 'current':
      statusClass = classes.statusCurrent;
      break;
    case 'past':
      statusClass = classes.statusError;
      Icon = <Warning className={classes.icon} />;
      break;
    case 'success':
      statusClass = classes.statusSuccess;
      break;
    default:
      statusClass = classes.statusDefault;
      Icon = <Warning className={classes.icon} />;
  }
  return (
    <Grid
      className={`${classes.status} ${statusClass}`}
      style={style}
      onClick={onClick}>
      {showIcon && Icon}
      <span className={classes.statusVal}>{value}</span>
    </Grid>
  );
}

function getOrderedColumns(data, namesToInclude) {
  return _.chain(data)
    .filter((item) => _.includes(namesToInclude, item.name)) // Filter items
    .sortBy((item) => _.indexOf(namesToInclude, item.name)) // Sort by index in namesToInclude
    .value();
}

function formatStatus(text, type) {
  return (
    text
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, (str) => str.toUpperCase())
      .trim() + ` ${type}`
  );
}

const INITIAL_FILTERS = {
  dc_id: [],
  req_dt_type: 'any',
  req_end_dt: null,
  sched_dt_type: 'on',
  sched_end_dt: null,
  req_dt: new Date(),
  sched_dt: new Date(),
  print_status: 'b',
};

function DeliveryTable(props) {
  const { classes } = props;

  const [data, setData] = useState([]);
  const [total, setTotal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });
  const [location, setLocation] = useState([]);

  const firstSectionData = getOrderedColumns(
    data,
    namesToIncludeInFirstSection
  );

  const secondSectionData = getOrderedColumns(
    data,
    namesToIncludeInSecondSection
  );

  const thirdSectionData = getOrderedColumns(data, ['TOTAL']);

  const firstColumns = [
    {
      title: `Total ${total}`,
      dataIndex: 'name',
      key: 'name',
      width: 75,
    },
    {
      title: 'Method Total',
      dataIndex: 'total',
      key: 'total',
      render: (data) => renderStatusCells(data, 'total', 'default', 'default'),
    },
    {
      title: 'Unscheduled or Unprinted',
      dataIndex: 'unscheduledOrUnprinted',
      key: 'unscheduledOrUnprinted',
      render: (data) => renderStatusCells(data, 'unscheduledOrUnprinted'),
    },
    {
      title: 'Pending Method',
      dataIndex: 'pendingMethod',
      key: 'pendingMethod',
      render: (data) => renderStatusCells(data, 'pendingMethod'),
    },
    {
      title: 'Pending Routing',
      dataIndex: 'pendingRouting',
      key: 'pendingRouting',
      render: (data) => renderStatusCells(data, 'pendingRouting'),
    },
    {
      title: 'Routed',
      dataIndex: 'routed',
      key: 'routed',
      render: (data) => renderStatusCells(data, 'routed'),
    },
    {
      title: 'Dispatched',
      dataIndex: 'dispatched',
      key: 'dispatched',
      render: (data) => renderStatusCells(data, 'dispatched'),
    },
    {
      title: 'Deployed (Has Not Arrived)',
      dataIndex: 'deployed',
      key: 'deployed',
      render: (data) => renderStatusCells(data, 'deployed'),
    },
    {
      title: 'Enroute',
      dataIndex: 'enroute',
      key: 'enroute',
      render: (data) => renderStatusCells(data, 'enroute'),
    },

    {
      title: 'Arrived In Progress',
      dataIndex: 'arrived',
      key: 'arrived',
      render: (data) => renderStatusCells(data, 'arrived'),
    },
    {
      title: 'Arrived ALL BAD',
      dataIndex: 'allbad',
      key: 'allbad',
      render: (data) => renderStatusCells(data, 'allbad'),
    },
    {
      title: 'Arrived ALL GOOD',
      dataIndex: 'allgood',
      key: 'allgood',
      render: (data) =>
        renderStatusCells(data, 'allgood', 'current', 'success'),
    },
  ];
  const secondColumns = [
    {
      title: 'Method',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Method Total',
      dataIndex: 'total',
      key: 'total',
      render: (data) => renderStatusCells(data, 'total', 'default', 'default'),
    },
    {
      title: 'Unscheduled or Unprinted',
      dataIndex: 'unscheduledOrUnprinted',
      key: 'unscheduledOrUnprinted',
      render: (data) => renderStatusCells(data, 'unscheduledOrUnprinted'),
    },
    {
      title: 'Pending Method',
      dataIndex: 'pendingMethod',
      key: 'pendingMethod',
      render: (data) => renderStatusCells(data, 'pendingMethod'),
    },
    {
      title: 'Assigned',
      dataIndex: 'Assigned',
      key: 'Assigned',
      width: 400,
      render: (data) =>
        renderStatusCells(data, 'assigned', 'current', 'success'),
    },
  ];

  const thirdSubColumns = [
    {
      title: 'Pending Routing',
      dataIndex: 'pendingRouting',
      key: 'pendingRouting',
      render: (data) => renderStatusCells(data, 'pendingRouting'),
    },
    {
      title: 'Routed',
      dataIndex: 'routed',
      key: 'routed',
      render: (data) => renderStatusCells(data, 'routed'),
    },
    {
      title: 'Dispatched',
      dataIndex: 'dispatched',
      key: 'dispatched',
      render: (data) => renderStatusCells(data, 'dispatched'),
    },
    {
      title: 'Deployed (Has Not Arrived)',
      dataIndex: 'deployed',
      key: 'deployed',
      render: (data) => renderStatusCells(data, 'deployed'),
    },
    {
      title: 'Enroute',
      dataIndex: 'enroute',
      key: 'enroute',
      render: (data) => renderStatusCells(data, 'enroute'),
    },

    {
      title: 'Arrived In Progress',
      dataIndex: 'arrived',
      key: 'arrived',
      render: (data) => renderStatusCells(data, 'arrived'),
    },
    {
      title: 'Arrived ALL BAD',
      dataIndex: 'allbad',
      key: 'allbad',
      render: (data) => renderStatusCells(data, 'allbad'),
    },
    {
      title: 'Arrived ALL GOOD',
      dataIndex: 'allgood',
      key: 'allgood',
      render: (data) =>
        renderStatusCells(data, 'allgood', 'current', 'success'),
    },
  ];

  const thirdColumns = [
    {
      title: 'Method',
      dataIndex: 'name',
      key: 'name',
      width: 90,
      render: () => 'ALL METHODS',
    },

    {
      title: 'Method Total',
      dataIndex: 'total',
      key: 'total',
      render: (data) => renderStatusCells(data, 'total', 'default', 'default'),
    },
    {
      title: 'Unscheduled or Unprinted',
      dataIndex: 'unscheduledOrUnprinted',
      key: 'unscheduledOrUnprinted',
      render: (data) => renderStatusCells(data, 'unscheduledOrUnprinted'),
    },
    {
      title: 'Pending Method',
      dataIndex: 'pendingMethod',
      key: 'pendingMethod',
      render: (data) => renderStatusCells(data, 'pendingMethod'),
    },
    {
      title: 'Total Assigned',
      dataIndex: 'assigned',
      key: 'assigned',
      width: 750,
      render: (data) => (
        <Grid style={{ textAlign: 'center' }}>
          <Grid>
            {renderStatusCells(data, 'assigned', 'current', 'success')}
          </Grid>

          <CustomTable
            columns={thirdSubColumns}
            data={[data]}
            headerStyle={{ backgroundColor: '#e0f7fa' }}
            backgroundColor={false}
            error={{ isError: 'error', message: 'There is no data' }}
          />
        </Grid>
      ),
    },
  ];

  function renderStatusCells(data, name, currCellType, pastCellType) {
    const curr = data[name + 'Curr'];
    const past = data[name + 'Past'];

    return (
      <>
        {!!curr && (
          <StatusCell
            onClick={() => {
              const params = {
                m_nm: data.name,
                bkt_nm: formatStatus(name, 'Curr'),
                sched_dt: dateFormat(filters.sched_dt),
              };
              props.history.push('/summary_detail', params);
            }}
            showIcon={false}
            classes={classes}
            value={curr}
            type={currCellType || 'current'}
          />
        )}
        {!!past && (
          <StatusCell
            onClick={() => {
              const params = {
                m_nm: data.name,
                bkt_nm: formatStatus(name, 'Past'),
                sched_dt: dateFormat(filters.sched_dt),
              };
              props.history.push('/summary_detail', params);
            }}
            classes={classes}
            style={{ marginLeft: 3 }}
            value={past}
            type={pastCellType || 'past'}
          />
        )}
      </>
    );
  }

  const fetchRouteSummary = async () => {
    const body = cleanObject({
      ...filters,
      req_dt: dateFormat(filters.req_dt),
      sched_dt: dateFormat(filters.sched_dt),
      sched_end_dt: dateFormat(filters.sched_end_dt),
      req_end_dt: dateFormat(filters.req_end_dt),
    });
    setIsLoading(true);
    try {
      const { summary, total } = await window.sch.post(
        `/api/wo/route_summary`,
        {
          ...body,
        }
      );
      setTotal(total);

      const data = Object.entries(summary).map(([key, val]) => ({
        ..._.mapKeys(val, (value, k) => _.camelCase(k)),
        name: key,
      }));
      setData(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLocation = async () => {
    try {
      const { place } = await window.sch.get(`/api/dt/places`);
      const data = place.map((loc) => ({
        value: loc.dc_id,
        label: loc.dc_nm,
      }));
      setLocation(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDateChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    fetchRouteSummary();
  };

  const handleClearFilters = () => {
    setFilters({ ...INITIAL_FILTERS });
  };

  const handleFilterFieldChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchLocation();
    fetchRouteSummary();
  }, []);

  return (
    <Grid>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ padding: '20px 0', alignItems: 'end' }}>
        <Grid
          container
          spacing={16}
          alignItems="center"
          style={{ paddingLeft: '5px' }}>
          <Grid item xs={2}>
            <CustomSelect
              name="print_status"
              options={PRINT_TYPE}
              label="Print Status"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.print_status}
            />
          </Grid>

          <Grid item xs={2}>
            <CustomSelect
              name="req_dt_type"
              options={REQUEST_TYPE}
              label="Request Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.req_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.req_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_dt', date)}
                customInput={<TextField margin="dense" label="Request From" />}
              />
            </Grid>
          )}

          {filters.req_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_end_dt || new Date()}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_end_dt', date)}
                customInput={<TextField margin="dense" label="Request To" />}
              />
            </Grid>
          )}

          <Grid item xs={2}>
            <CustomSelect
              name="sched_dt_type"
              options={SCHEDULE_TYPE}
              label="Schedule Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.sched_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.sched_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_dt', date)}
                customInput={
                  <TextField margin="dense" label="Scheduled From" />
                }
              />
            </Grid>
          )}

          {filters.sched_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_end_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_end_dt', date)}
                customInput={<TextField margin="dense" label="Scheduled To" />}
              />
            </Grid>
          )}
        </Grid>

        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="dc_id"
            label="Location"
            options={location}
            value={filters.dc_id}
            placeholder="Select Location"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={applyFilters}
            disabled={isLoading}>
            Show Records
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleClearFilters}
            disabled={isLoading}>
            Clear All
          </Button>
        </Grid>
      </Grid>
      <Grid
        style={{
          overflowY: 'scroll',
          height: '70vh',
        }}>
        <Paper>
          <CustomTable
            classes={classes}
            columns={firstColumns}
            data={firstSectionData}
            backgroundColor={false}
            headerStyle={{ backgroundColor: '#e0f7fa' }}
            error={{ isError: 'error', message: 'There is no data' }}
          />
        </Paper>
        <Paper
          style={{
            marginTop: 10,
          }}>
          <CustomTable
            classes={classes}
            columns={secondColumns}
            data={secondSectionData}
            backgroundColor={false}
            headerStyle={{ backgroundColor: '#e0f7fa' }}
            error={{ isError: 'error', message: 'There is no data' }}
          />
        </Paper>

        <Paper
          style={{
            marginTop: 10,
          }}>
          <CustomTable
            classes={classes}
            columns={thirdColumns}
            data={thirdSectionData}
            backgroundColor={false}
            headerStyle={{ backgroundColor: '#e0f7fa' }}
            error={{ isError: 'error', message: 'There is no data' }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(DeliveryTable);
