import PropTypes from 'prop-types';
import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoaderRow from '../IndexingAdmin/common/LoaderRow';

const styles = () => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },

  table: {
    minWidth: 700,
  },

  stickyHeader: {
    top: 0,
    left: 0,
    Zindex: '9999',
    position: 'sticky',
    backgroundColor: 'white',
  },
});

function CustomTable(props) {
  const {
    data,
    error,
    columns,
    classes,
    isLoading,
    idKey = '_id',
    setSortData,
    defaultSortData = {},
    sortData: sortDataProp,
    selectedId,
    onRowClick,
    backgroundColor = true,
    rowTitle = '',
    rowTitleSelected = '',
    hoverEffect = true,
  } = props;

  const sortData = sortDataProp || defaultSortData;

  const { isError, message } = error;
  const columnWidth = 100 / columns.length;

  const StyledDataRow = hoverEffect
    ? withStyles((theme) => ({
        root: {
          cursor: 'pointer',
          verticalAlign: 'top',
        },

        ...(backgroundColor
          ? {
              hover: {
                '&$hover:hover': {
                  backgroundColor: theme.palette.primary['100'],
                },
              },
              selected: {
                '&$selected': {
                  backgroundColor: theme.palette.primary['50'],
                },
              },
            }
          : {
              hover: {
                '&$hover:hover': {
                  backgroundColor: 'transparent',
                },
              },
            }),
      }))(TableRow)
    : TableRow;

  const handleRequestSort = (event, property) => {
    if (!setSortData) return;
    const orderBy = property;
    let order = 1;

    if (sortData[orderBy] === 1) {
      order = -1;
    } else if (sortData[orderBy] === -1) {
      order = 0;
    }

    if (order === 0) {
      setSortData((prev) => {
        const { [property]: _, ...rest } =
          prev === null ? defaultSortData : prev;
        return rest;
      });

      return;
    }

    setSortData((prev) => ({
      ...(prev === null ? defaultSortData : prev),
      [property]: order,
    }));
  };

  const active = (key) => {
    if (sortData[key] === 0) {
      return false;
    }
    const sortKey = Object.keys(sortData);

    if (sortKey.includes(key)) {
      return true;
    }
  };

  const direction = (data) => {
    if (!data) {
      return;
    }

    const arrowDirections = data === 1 ? 'asc' : 'desc';
    return arrowDirections;
  };

  const handleClick = (e, isSelectedId, id) => {
    if (!onRowClick) {
      return;
    }

    if (isSelectedId) {
      return onRowClick(null, e);
    }

    onRowClick(id, e);
  };

  const TableStatus = ({ isError, emptyMessage, col }) => {
    return (
      <TableRow>
        <TableCell colSpan={col} align="center">
          <Typography
            variant="inherit"
            color={isError ? 'error' : 'textSecondary'}>
            {emptyMessage}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className={classes.root} style={props.baseStyle}>
      <Table
        padding="dense"
        cellPadding={4}
        className={classes.table}
        style={{ borderCollapse: 'separate', borderSpacing: 1 }}>
        <TableHead className={classes.stickyHeader}>
          <TableRow style={props.headerStyle}>
            {columns?.map((header) => (
              <TableCell
                key={header.key}
                style={{
                  width: header.width || columnWidth,
                  textAlign: header.alignTitle,
                  fontWeight: 'bold',
                }}>
                <TableSortLabel
                  active={header.sortable && active(header.dataIndex)}
                  direction={direction(sortData[header.dataIndex])}
                  onClick={(e) => handleRequestSort(e, header.dataIndex)}
                  disabled={!header.sortable}
                  hideSortIcon={!header.sortable}>
                  <Tooltip
                    title={header.tooltip || header.title}
                    placement="top">
                    <span>{header.title}</span>
                  </Tooltip>
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <LoaderRow col={columns.length} />
          ) : data?.length ? (
            data.map((data, idx) => {
              const isSelectedId = !isNaN(selectedId)
                ? data[idKey]
                  ? selectedId === data[idKey]
                  : selectedId === idx
                : false;

              return (
                <StyledDataRow
                  hover
                  key={idx}
                  selected={isSelectedId}
                  onClick={(e) => handleClick(e, isSelectedId, data[idKey])}
                  className={classes.tableRow}
                  style={props.rowStyle || {}}
                  title={isSelectedId ? rowTitleSelected : rowTitle}>
                  {columns?.map((column, cIdx) => (
                    <TableCell key={cIdx}>
                      {column['render']
                        ? column.render(data, idx)
                        : data[column.dataIndex] || 'N/A'}
                    </TableCell>
                  ))}
                </StyledDataRow>
              );
            })
          ) : (
            <TableStatus
              isError={isError}
              emptyMessage={message}
              col={columns.length}
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.propTypes = {
  data: PropTypes.array,
  error: PropTypes.object,
  sortData: PropTypes.object,
  onRowClick: PropTypes.func,
  setSortData: PropTypes.func,
  selectedId: PropTypes.number,
  backgroundColor: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomTable);
