import {
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TextInfo } from '../HourlyWos/components';
import Loader from '../Shared/Loader';
import { fetchJotformQuestions } from './api';
import { Edit as EditIcon, Save as SaveIcon } from '@material-ui/icons';
import { OPTIONS } from './constants';
import Select from 'react-select';

const QuestionNode = (props) => {
  const {
    index,
    disabled = false,
    questionKey,
    modelSource,
    questionTitle,
    isMetaKey,
    toggleMetaKey,
    modelMap,
    defaultValue,
    onModelMapChange,
    onDefaultValueChange,
  } = props;

  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [modelMapValue, setModelMapValue] = useState(modelMap || '');
  const [defaultValueValue, setDefaultValueValue] = useState(
    defaultValue || ''
  );

  const options = modelSource === 'WO' ? OPTIONS.WO : OPTIONS.PT;

  const selectOptions = options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

  if (isMetaKey) {
    selectOptions.unshift({ value: 'N/A', label: 'N/A' });
  }

  return (
    <Grid
      container
      spacing={16}
      style={{
        marginTop: index === 0 ? '6px' : '16px',
        borderTop: index === 0 ? 'none' : '1px solid #ccc',
        background: changed ? 'lightyellow' : 'white',
      }}
      alignItems="center">
      {/* Meta Key Checkbox */}
      <Grid item xs={1}>
        <Checkbox
          disabled={disabled}
          checked={isMetaKey}
          onChange={toggleMetaKey}
        />
      </Grid>

      {/* Question Title */}
      <Grid item xs={4}>
        <span style={{ fontSize: 14, fontWeight: 'bold', display: 'block' }}>
          {questionTitle}
        </span>
        <span style={{ fontSize: 12, display: 'block', marginTop: '4px' }}>
          {`Key: ${questionKey}`}
        </span>
      </Grid>

      {/* Model Map Input */}
      <Grid item xs={3}>
        {options.length ? (
          <Select
            options={selectOptions}
            value={
              isMetaKey
                ? { value: 'N/A', label: 'N/A' }
                : selectOptions.find((opt) => opt.value === modelMapValue) ||
                  null
            }
            onChange={(selectedOption) => {
              if (!changed) {
                setChanged(true);
              }
              setModelMapValue(selectedOption ? selectedOption.value : '');
            }}
            isDisabled={disabled || isMetaKey || !editing}
            styles={{
              control: (provided, state) => ({
                ...provided,
                backgroundColor:
                  disabled || isMetaKey || !editing ? '#f0f0f0' : 'white',
              }),
              option: (provided) => ({
                ...provided,
                color: '#000',
              }),
            }}
            placeholder="Choose data source "
            isClearable={!isMetaKey}
          />
        ) : (
          <TextField
            fullWidth
            disabled={disabled || isMetaKey || !editing}
            value={isMetaKey ? 'N/A' : modelMapValue || ''}
            onChange={(e) => {
              if (!changed) {
                setChanged(true);
              }
              setModelMapValue(e.target.value);
            }}
            variant="standard"
            size="small"
          />
        )}
      </Grid>

      {/* Default Value Input */}
      <Grid item xs={3}>
        <TextField
          fullWidth
          disabled={disabled || isMetaKey || !editing}
          value={isMetaKey ? 'N/A' : defaultValueValue || ''}
          onChange={(e) => {
            if (!changed) {
              setChanged(true);
            }
            setDefaultValueValue(e.target.value);
          }}
          variant="standard"
          size="small"
        />
      </Grid>

      {/* Action Buttons */}
      <Grid item xs={1}>
        <IconButton
          disabled={disabled}
          variant="contained"
          color="secondary"
          onClick={() => {
            if (editing) {
              onModelMapChange(modelMapValue);
              onDefaultValueChange(defaultValueValue);
              setChanged(false);
            }

            setEditing(!editing);
          }}
          style={{ marginRight: '8px' }}>
          {editing ? (
            <SaveIcon fontSize="small" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButton>
      </Grid>
    </Grid>
  );
};

const TemplateDetail = ({
  isFetching = false,
  openSnack,
  template,
  onRemoveClick,
  isRemovingTemplate,
  isSavingTemplate,
  onChange,
  onSave,
}) => {
  const [questions, setQuestions] = useState([]);
  const [isFetchingQuestions, setIsFetchingQuestions] = useState(false);

  const formId = template?.formId;

  useEffect(() => {
    if (!template?.formId) {
      return;
    }

    fetchJotformQuestions(template.formId, {
      before: () => setIsFetchingQuestions(true),
      after: () => setIsFetchingQuestions(false),
      onSuccess: (data) => {
        setQuestions(data);
      },
      onError: (error) => {
        console.error(error);
        openSnack({
          message: error.message || 'Failed to fetch questions',
          variant: 'error',
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  const handleRemoveClick = () => {
    onRemoveClick(template);
  };

  const handleChange = (changes) => {
    onChange(template.formId, changes);
  };

  return (
    <div style={{ padding: '0px 16px' }}>
      {!template ? (
        <TextInfo value={'No template record selected'} />
      ) : isFetching || isFetchingQuestions ? (
        <Loader type="circular" />
      ) : template ? (
        <>
          <span
            style={{
              fontSize: '14px',
              marginBottom: 14,
              fontWeight: 'bold',
              cursor: 'pointer',
            }}>{`Jotform Template: ${
            template.formName
              ? `"${template.formName}" (${template.formId})`
              : template.formId
          } `}</span>

          <Divider
            style={{
              marginBottom: '10px',
              marginTop: '10px',
              color: 'grey',
            }}
          />

          <TextField
            required
            InputLabelProps={{
              shrink: true,
            }}
            style={{
              width: '40%',
            }}
            margin="dense"
            name="formName"
            label="Form Name"
            value={template.formName}
            onChange={(e) => {
              handleChange({ formName: e.target.value });
            }}
          />

          <TextField
            select
            required
            InputLabelProps={{
              shrink: true,
            }}
            margin="dense"
            style={{
              width: '40%',
              marginLeft: '16px',
            }}
            name="modelSource"
            label="Model Source"
            value={template.modelSource}
            onChange={(e) => {
              handleChange({ modelSource: e.target.value });
            }}>
            <MenuItem value="WO">Workorder</MenuItem>
            <MenuItem value="PT">Patient</MenuItem>
          </TextField>

          <Divider
            style={{
              marginBottom: '10px',
              marginTop: '10px',
              color: 'grey',
            }}
          />

          <div
            style={{
              padding: '12px',
              border: '1px solid #b3b3b3',
              borderRadius: '4px',
            }}>
            <Grid
              spacing={16}
              container
              style={{
                borderBottom: '1px solid #ccc',
              }}>
              <Grid item xs={1} style={{ fontSize: 14 }}>
                Meta Key
              </Grid>
              <Grid item xs={4} style={{ fontSize: 14 }}>
                Title
              </Grid>
              <Grid item xs={3} style={{ fontSize: 14 }}>
                Data Key
              </Grid>
              <Grid item xs={3} style={{ fontSize: 14 }}>
                Default Value
              </Grid>
              <Grid item xs={1} style={{ fontSize: 14 }}>
                Actions
              </Grid>
            </Grid>
            {!questions || !questions.length ? (
              <Typography
                style={{
                  marginTop: '12px',
                  padding: '24px',
                  textAlign: 'center',
                  fontSize: '14px',
                  color: 'red',
                }}>
                No questions found. Please make sure it is a valid Jotform
                template
              </Typography>
            ) : (
              <div
                style={{
                  maxHeight: '50vh',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  marginTop: '10px',
                }}>
                {questions.map((question, idx) => (
                  <QuestionNode
                    modelSource={template.modelSource}
                    key={idx}
                    index={idx}
                    disabled={isRemovingTemplate || isSavingTemplate}
                    questionKey={question.prefill_key}
                    isMetaKey={question.prefill_key === template.metaKey}
                    toggleMetaKey={() => {
                      handleChange({
                        metaKey:
                          question.prefill_key === template.metaKey
                            ? ''
                            : question.prefill_key,
                      });
                    }}
                    questionTitle={question.text}
                    modelMap={template.modelMapping[question.prefill_key]}
                    defaultValue={
                      template.defaultValueMapping[question.prefill_key]
                    }
                    onModelMapChange={(newVal) => {
                      handleChange({
                        modelMapping: {
                          ...template.modelMapping,
                          [question.prefill_key]: newVal,
                        },
                      });
                    }}
                    onDefaultValueChange={(newVal) => {
                      handleChange({
                        defaultValueMapping: {
                          ...template.defaultValueMapping,
                          [question.prefill_key]: newVal,
                        },
                      });
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          {template.changed && (
            <Typography
              style={{
                fontSize: '14px',
                color: 'red',
                margin: '8px 0px',
              }}>
              {'You have unsaved changes.'}
            </Typography>
          )}
          <div
            style={{
              marginTop: '26px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Button
              disabled={
                isSavingTemplate ||
                !template.changed ||
                !questions ||
                !questions.length
              }
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                onSave(template);
              }}>
              {isSavingTemplate ? 'Saving...' : 'Save'}
            </Button>
            <Button
              disabled={isRemovingTemplate}
              variant="outlined"
              style={
                template._id
                  ? {
                      borderColor: 'red',
                      borderWidth: '2px',
                      color: 'red',
                    }
                  : {}
              }
              size="small"
              color="primary"
              onClick={handleRemoveClick}>
              {isRemovingTemplate ? 'Removing...' : 'Remove'}
            </Button>
          </div>
        </>
      ) : (
        <TextInfo value={`Template record not found.`} type="error" />
      )}
    </div>
  );
};

export default TemplateDetail;
