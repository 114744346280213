import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

import Filters from './Filters';
import { dateFormat } from '../../util/date';
import CustomTable from '../common/CustomTable';
import { open_snack_ac } from '../../actions/snack.ac';
import { getAnnouncements } from '../../services/announcement';
import PaginationWithReset from '../IndexingAdmin/common/PaginationWithReset';

const initial_filters = {
  page: 0,
  title: null,
  pageSize: 10,
  end_date: null,
  start_date: null,
  skipAccessControl: true,
};
const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 80,
  },
  {
    title: 'Subtitle',
    dataIndex: 'subtitle',
    key: 'subtitle',
    width: 80,
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 150,
  },
  {
    title: 'Should Acknowledge',
    dataIndex: 'shouldAck',
    key: 'shouldAck',
    render: (data) => (data.shouldAck ? 'Yes' : 'No'),
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    render: (data) => data.roles.join(', '),
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sortable: true,
    render: (data) => (
      <>{dateFormat(data.createdAt, 'MM-DD-YYYY hh:mm:ss a')}</>
    ),
  },
];

const Announcements = (props) => {
  const { open_snack_ac } = props;

  const [data, setData] = useState({});
  const [filters, setFilters] = useState(initial_filters);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAnnouncements = async (payload = {}) => {
    try {
      setIsLoading(true);
      const result = await getAnnouncements(payload);
      setData(result);
    } catch (err) {
      setIsLoading(false);
      open_snack_ac({
        variant: 'error',
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAnnouncements(initial_filters);
  }, []);

  const handlePageChange = (newPage) => {
    console.log(newPage, 'newPage');
    fetchAnnouncements({ ...initial_filters, page: newPage - 1 });
  };

  const onFilterChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({ ...initial_filters });
  };

  const applyFilters = () => {
    const page = 0;
    const payload = {
      ...filters,
      start_date: dateFormat(filters.start_date),
      end_date: dateFormat(filters.end_date),
    };
    fetchAnnouncements({ ...payload, page });
  };

  return (
    <Grid container style={{ padding: 16 }}>
      <Grid item xs={10}>
        <Typography variant="h4" gutterBottom>
          Announcements
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          style={{ fontSize: 12 }}
          onClick={() => props.history.push('/announcements/registration')}>
          Create Announcements
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Filters
          filters={filters}
          isFetching={isLoading}
          applyFilters={applyFilters}
          onFilterChange={onFilterChange}
          handleClearFilters={handleClearFilters}
        />
      </Grid>
      <Grid item xs={12}>
        {!!data?.pagination?.totalRecords && (
          <PaginationWithReset
            sortSupport={false}
            data={data?.pagination}
            isFetching={isLoading}
            handlePageChange={handlePageChange}
          />
        )}
        <CustomTable
          columns={columns}
          data={data.announcements}
          isLoading={isLoading}
          error={{ message: 'There is no Announcements' }}
          baseStyle={{
            height: '75vh',
            overflow: 'scroll',
            overflowX: 'hidden',
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  open_snack_ac,
};

export default connect(null, mapDispatchToProps)(Announcements);
