import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  MenuItem,
  CircularProgress,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
} from '@material-ui/core';

const AsyncSelect = ({
  label = 'Search',
  apiEndpoint,
  paramName = 'searchStr',
  resultMap = (data) => ({ label: data.nm, value: data._id }),
  minSearchLength = 3,
  debounceTime = 1000,
  onSelect,
  disabled = false,
  dataKey,
  baseParams = {},
}) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchOptions = async () => {
      if (inputValue.length < minSearchLength) return;

      const postData = {
        [paramName]: inputValue,
        ...baseParams,
      };
      setLoading(true);
      try {
        const result = await window.sch.post(apiEndpoint, postData);
        const data = result[dataKey].map(resultMap);
        setOptions(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    const timeoutId = setTimeout(fetchOptions, debounceTime);
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setOpen(true);
  };

  const handleChange = (value) => {
    setInputValue(value.label);
    onSelect(value);
    setOpen(false);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div>
      <TextField
        fullWidth
        label={label}
        value={inputValue}
        disabled={disabled}
        onChange={handleInputChange}
        variant="outlined"
        inputRef={anchorEl}
        onClick={() => setOpen(true)}
      />
      <Popper
        open={open}
        anchorEl={anchorEl.current}
        placement="bottom-start"
        style={{ zIndex: 1 }}>
        <Paper>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <ClickAwayListener onClickAway={handleClickAway}>
              <MenuList>
                {options.length > 0 ? (
                  options.map((option, idx) => (
                    <MenuItem key={idx} onClick={() => handleChange(option)}>
                      {option.label}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          )}
        </Paper>
      </Popper>
    </div>
  );
};

export default AsyncSelect;
