import {
  FormControl,
  FormGroup,
  FormLabel,
  TextField,
} from '@material-ui/core';
import React from 'react';

const EmergencyContact = (props) => {
  const { contact = {}, onChange } = props;

  return (
    <FormGroup
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'unset',
      }}>
      <TextField
        onChange={(e) => {
          onChange({
            ...contact,
            name: e.target.value,
          });
        }}
        required={true}
        value={contact.name || ''}
        label="a) Name"
        style={{ margin: '8px 8px 8px 0' }}
        placeholder="Name"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        onChange={(e) => {
          onChange({
            ...contact,
            address: e.target.value,
          });
        }}
        required={true}
        label="b) Address"
        value={contact.address || ''}
        style={{ margin: '8px 8px 8px 0' }}
        placeholder="Address"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        onChange={(e) => {
          onChange({
            ...contact,
            phone: e.target.value,
          });
        }}
        label="c) Phone"
        value={contact.phone || ''}
        style={{ margin: '8px 8px 8px 0' }}
        placeholder="Phone"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        onChange={(e) => {
          onChange({
            ...contact,
            email: e.target.value,
          });
        }}
        value={contact.email || ''}
        style={{ margin: '8px 8px 8px 0' }}
        label="d) Email"
        placeholder="Email"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </FormGroup>
  );
};

const InitialVentMetaForm = (props) => {
  const { contactInfo = {}, onChange, classes } = props;

  return (
    <div
      style={{
        border: '1px solid #ccc',
        paddingLeft: 8,
        marginTop: 4,
        marginBottom: 8,
      }}>
      <FormControl
        required
        component="fieldset"
        className={classes.formControl}>
        <FormLabel component="label" required={false}>
          1) Who is responsible for the care of the patient (self, caregiver,
          etc.) *
        </FormLabel>
        {/* <FormHelperText error>
                  Notes/comments regarding this vist/encounter
                </FormHelperText> */}
        <FormGroup classes={classes.formGroupRow}>
          <TextField
            required={true}
            onChange={(e) => {
              onChange({
                ...contactInfo,
                responsible_for_care: e.target.value,
              });
            }}
            value={contactInfo.responsible_for_care || ''}
            style={{ margin: '8px 8px 8px 0' }}
            placeholder="Person responsible for the care"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormGroup>
      </FormControl>

      <FormControl
        required
        component="fieldset"
        className={classes.formControl}>
        <FormLabel component="label" required={false}>
          2) Primary Emergency Contact *
        </FormLabel>
        <EmergencyContact
          classes={classes}
          contact={contactInfo.emergency_contact}
          onChange={(contact) => {
            onChange({
              ...contactInfo,
              emergency_contact: contact,
            });
          }}
        />
      </FormControl>

      <FormControl
        required
        component="fieldset"
        className={classes.formControl}>
        <FormLabel component="label" required={false}>
          3) Secondary Emergency Contact *
        </FormLabel>
        <EmergencyContact
          classes={classes}
          contact={contactInfo.secondary_emergency_contact}
          onChange={(contact) => {
            onChange({
              ...contactInfo,
              secondary_emergency_contact: contact,
            });
          }}
        />
      </FormControl>
    </div>
  );
};

export default InitialVentMetaForm;
